import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrl: './accordion.component.scss'
})
export class AccordionComponent {

  openedItemIndex: number | null = null

  @Input() items: AccordionItem[] = []
  @Input() trackingPrefix: string | null = null;


  toggle(itemIndex: number) {
    this.openedItemIndex = this.openedItemIndex === itemIndex ? null : itemIndex;
  }

  getContentHeight(index: number): string {
    if (index === this.openedItemIndex) {
      const contentElement = document.querySelector(`#content-${index}`) as HTMLElement;
      return contentElement ? `${contentElement.scrollHeight}px` : '0px';
    }
    return '0px';
  }
}

export class AccordionItem {
  title: string
  content?: string
  template?: TemplateRef<any>
}
