import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, provideRouter, Router} from '@angular/router';
import {FrontendVideoData, videoData} from '../videos';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {DemodeskService} from "../../demo-booking/demodesk.service";

@Component({
  selector: 'app-video-details',
  templateUrl: './video-overview-page.component.html',
  styleUrl: './video-overview-page.component.scss',
})
export class VideoOverviewPageComponent implements OnInit{
  videos = videoData;
  video: FrontendVideoData = null;
  videoUrl: SafeResourceUrl

  constructor(
    private route: ActivatedRoute,
    private demodeskService: DemodeskService,
    private sanitizer: DomSanitizer,
    private router: Router) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const slug = params.get('slug');
      if (slug) {
        this.video = this.videos.find(video => video.slug === slug);
        if (!this.video) {
          this.router.navigateByUrl("/company/hr-insights-hub");
        } else {
          this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.video.vimeo);
        }
      }
    });
  }



  routeToDemoDesk(context: string) {
    this.demodeskService.navToDemodesk(context)
  }

}
