<div (appClickOutside)="close()">
  <div class="burger-menu" [class.active]="active" (click)="onBurgerClicked()">
    <div class="burger-container">
      <div class="burger-inner"></div>
    </div>
  </div>

  <div class="menu-list" *ngIf="show" #menu>

    <a routerLink="/talent" (click)="close()" class="menu-link" [ngClass]="{'active-menupoint': router.url.includes('talent')}">
      Studenten
    </a>

    <div>
      <p class="menu-link" (click)="togglePopupMenu()" id="popupButton" [ngClass]="{'active-menupoint': router.url.includes('company')}">
        Unternehmen
        <svg [ngClass]="{'rotated': isPopupOpen}" class="nav-arrow-icon" width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.5297 9.52974C6.23673 9.8227 5.76095 9.8227 5.46798 9.52974L0.967981 5.02974C0.675013 4.73677 0.675013 4.26099 0.967981 3.96802C1.26095 3.67505 1.73673 3.67505 2.0297 3.96802L6.00001 7.93833L9.97032 3.97036C10.2633 3.67739 10.7391 3.67739 11.032 3.97036C11.325 4.26333 11.325 4.73911 11.032 5.03208L6.53204 9.53208L6.5297 9.52974Z"/>
        </svg>
      </p>
      <!-- no real popup (just the same name as in the app.component)-->
      <div class="popup-menu"  [ngClass]="{'open': isPopupOpen}" id="popupMenu">
        <a class="nav-sub-button" (click)="close()" routerLink="/company">Für Unternehmen</a>
        <a class="nav-sub-button" (click)="close()" routerLink="/company/hr-insights-hub">HR Insights Hub</a>
        <a class="nav-sub-button" (click)="close(); routeToDemoDesk()" routerLink="/navbar">Demo buchen</a>
      </div>
    </div>

    <a routerLink="/ueber-uns" class="menu-link">Über uns</a>

    <div class="divider"></div>

    <a *ngIf="isTalentView()" class="menu-link" (click)="routeToTalentAgent()">
      Login
    </a>

    <a *ngIf="isTalentView()" class="menu-link"  (click)="routeToTalentAgentOb()">
      <button class="accent-button ita_initiate-checkout ita_format-talentagent page-content-cta">Registrieren</button>
    </a>

    <a *ngIf="!isTalentView()" class="menu-link" (click)="routeToCompanyLogin()">
      Login
    </a>

    <a *ngIf="!isTalentView()" class="menu-link" (click)="routeToDemoDesk()">
      <button class="accent-button">Demo buchen</button>
    </a>

  </div>
</div>

