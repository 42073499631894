<section class="header">
  <h2>Insights Hub</h2>
  <h1>Recruiting Video Lab</h1>
  <h3>🔍 für Unternehmen, Recruiter und HR Interessierte</h3>
</section>
<section>
  <p class="heading-description">
    Videoreihe
  </p>
  <h4>Was wünscht sich die Gen Z beim Berufseinstieg?</h4>
  <div class="video-card-container">

    <video-card *ngFor="let video of videos; let i = index"
                [index]="i">
    </video-card>


  </div>
</section>

<section class="report-section">


  <p class="heading-description">
    Reports
  </p>
  <h4>Online-Studie: Karriereinteressen von Studierenden</h4>
  <div class="video-card-container">
    <div class="report-container">
      <img class="report-thumbnail" [routerLink]="'/company/hr-insights-hub/report-gen-z-wuensche-aengste-berufseinstieg'" src="assets/images/hr-report-thumbnail.png" alt="">


      <div class="report-description-container">
        <div>
          <h5>Wünsche und Ängste der Gen Z zum Berufseinstieg</h5>
          <p class="report-description">Was erwarten Nachwuchskräfte vom ersten Arbeitgeber nach dem Studium? Im Report
            präsentieren wir die Ergebnisse unserer Online-Befragung unter Studierenden, die wichtigsten Kriterien bei
            der Arbeitgeberwahl und praxisnahe Tipps für die Gewinnung und Bindung junger Talente.</p>
        </div>

        <div class="report-bottom">
          <a class="call-to-action-video" [routerLink]="'/company/hr-insights-hub/report-gen-z-wuensche-aengste-berufseinstieg'">
            Zum Report
          </a>
        </div>
      </div>
    </div>

    <div></div>
  </div>
</section>

<a href="company/hr-insights-hub/details/arbeitgeberwahl-faktoren" style="display:none"></a>
<a href="company/hr-insights-hub/details/work-life-balance-durch-flexibilitaet" style="display:none"></a>
<a href="company/hr-insights-hub/details/jobangebot-optimieren" style="display:none"></a>
<a href="company/hr-insights-hub/details/bewerbungsprozess-vereinfachen" style="display:none"></a>
<a href="company/hr-insights-hub/details/talente-langfristig-binden" style="display:none"></a>
