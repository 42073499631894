import {Component, Input} from '@angular/core';

@Component({
  selector: 'checked-point',
  templateUrl: './checked-point.component.html',
  styleUrls: ['./checked-point.component.scss']
})
export class CheckedPointComponent {
  @Input() iconSrc: string = './assets/images/check.svg';
  @Input() boldText: string = '';
  @Input() description: string = '';
}
