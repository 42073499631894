import {HostListener, Injectable} from '@angular/core';
import {MediaMatcher} from "@angular/cdk/layout";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  mobileQuery: MediaQueryList;
  tabletQuery: MediaQueryList;

  private screenWidthSubject = new Subject<number>();
  public screenWidth$ = this.screenWidthSubject.asObservable();
  public sideMenuExpanded: boolean = true;

  constructor(private media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.tabletQuery = media.matchMedia('(max-width: 1180px)');
    this.screenWidthSubject.next(window.innerWidth);
    window.addEventListener('resize', () => {
      this.screenWidthSubject.next(window.innerWidth);
    });
  }

  getScreenWidth(): number {
    return window.innerWidth;
  }

  getScreenHeight(): number {
    return window.innerHeight;
  }

  getMaxWidthQuery(widthPx: number) {
    return this.media.matchMedia(`(max-width: ${widthPx}px)`);
  }

  scrollLockBody() {
    document.body.setAttribute("style", "overflow:hidden")
  }

  removeScrollLockBody() {
    document.body.setAttribute("style", "overflow:initial")
  }

  isIOS(): boolean {
    return navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }

  isAppleDevice(): boolean {
    return /iPad|iPhone|iPod|MacIntel|Macintosh/.test(navigator.userAgent)
  }

  isMobile() {
    return this.mobileQuery.matches
  }

  isTablet() {
    return this.tabletQuery.matches
  }

  setSideMenuState(expanded: boolean){
    this.sideMenuExpanded = expanded;
  }
}
