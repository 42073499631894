<div class="video-card">
  <video-preview
    [previewUrl]="video.previewUrl"
    [videoUrl]="video.videoUrl"
    [index]=index>
  </video-preview>

  <div class="description-container">
    <div>
      <h5>{{ video.title }}</h5>
      <p class="description">{{ video.description }}</p>
    </div>
    <a class="call-to-action-video" [routerLink]="'/company/hr-insights-hub/details/' + videoData[index].slug">
      Video anschauen
    </a>
  </div>
</div>
