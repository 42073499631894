import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.shared.TermsAndConditionsController
 */

@Injectable()
@ResourceParams({})
export class TermsAndConditionsResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/public/termsAnsConditions/latest',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getLatestTermsAndConditions: IResourceMethodObservableStrict<null, null, null, Blob> | undefined
    getLatestTermsAndConditions(): Promise<Blob> {
        if (!this._getLatestTermsAndConditions) throw new Error("resource has not been properly initialized")
        return this._getLatestTermsAndConditions(null, null, null).toPromise()
    }

}