import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, BookingFilterData, CompanyBookingData, ApplicationData, CompanyEmploymentPositionData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyBookingController
 */

@Injectable()
@ResourceParams({})
export class CompanyBookingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/bookings/{id}/addPositions',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _addPositionsToBooking: IResourceMethodObservableStrict<number[], null, {id: number}, CompanyBookingData> | undefined
    addPositionsToBooking(requestBody: number[], id: number): Promise<CompanyBookingData> {
        if (!this._addPositionsToBooking) throw new Error("resource has not been properly initialized")
        return this._addPositionsToBooking(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/bookings/active',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _checkForActiveBookings: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    checkForActiveBookings(): Promise<boolean> {
        if (!this._checkForActiveBookings) throw new Error("resource has not been properly initialized")
        return this._checkForActiveBookings(null, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/bookings/{id}/applications',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicationsForBooking: IResourceMethodObservableStrict<null, null, {id: number}, ApplicationData[]> | undefined
    getApplicationsForBooking(id: number): Promise<ApplicationData[]> {
        if (!this._getApplicationsForBooking) throw new Error("resource has not been properly initialized")
        return this._getApplicationsForBooking(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company/bookings',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFilteredBookings: IResourceMethodObservableStrict<BookingFilterData, null, null, PagedData<CompanyBookingData>> | undefined
    getFilteredBookings(requestBody: BookingFilterData): Promise<PagedData<CompanyBookingData>> {
        if (!this._getFilteredBookings) throw new Error("resource has not been properly initialized")
        return this._getFilteredBookings(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/bookings/{id}/positions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPositionsForBooking: IResourceMethodObservableStrict<null, null, {id: number}, CompanyEmploymentPositionData[]> | undefined
    getPositionsForBooking(id: number): Promise<CompanyEmploymentPositionData[]> {
        if (!this._getPositionsForBooking) throw new Error("resource has not been properly initialized")
        return this._getPositionsForBooking(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/bookings/{id}/removePositions',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _removePositionsFromBooking: IResourceMethodObservableStrict<number[], null, {id: number}, CompanyBookingData> | undefined
    removePositionsFromBooking(requestBody: number[], id: number): Promise<CompanyBookingData> {
        if (!this._removePositionsFromBooking) throw new Error("resource has not been properly initialized")
        return this._removePositionsFromBooking(requestBody, null, {id: id}).toPromise()
    }

}