<div id="main-wrapper">

  <div
    *ngIf="showNavbar"
    id="navbar-wrapper">
    <div
      id="navbar" class="flex flex-space-between-center center" *ngIf="!mediaService.getMaxWidthQuery(1200).matches">
      <img style="cursor: pointer" src="assets/images/talentagent-logo.png" routerLink="/talent" alt="">

      <div class="flex row center gap-xl">
        <a routerLink="/talent">
          <button
            [ngClass]="{'active-menupoint': router.url.startsWith('/talent')}"
            class="nav-button" >Für Studenten</button>
        </a>

        <div class="popup-menu-container">
          <button class="nav-button" (click)="togglePopupMenu()" id="popupButton" [ngClass]="{'active-menupoint': router.url.includes('company')}">
            Für Unternehmen
            <svg  [ngClass]="{'rotated': isPopupOpen}" class="nav-arrow-icon" width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5297 9.52974C6.23673 9.8227 5.76095 9.8227 5.46798 9.52974L0.967981 5.02974C0.675013 4.73677 0.675013 4.26099 0.967981 3.96802C1.26095 3.67505 1.73673 3.67505 2.0297 3.96802L6.00001 7.93833L9.97032 3.97036C10.2633 3.67739 10.7391 3.67739 11.032 3.97036C11.325 4.26333 11.325 4.73911 11.032 5.03208L6.53204 9.53208L6.5297 9.52974Z"/>
            </svg>
          </button>

          <div class="popup-menu"  [ngClass]="{'open': isPopupOpen}" id="popupMenu">
            <button class="nav-sub-button" (click)="navigateTo('/company')">Infos für Unternehmen</button>
            <button class="nav-sub-button" (click)="navigateTo('/company/hr-insights-hub')">HR Insights Hub</button>
            <button class="nav-sub-button" (click)="routeToDemoDesk('Navbar')">Demo buchen</button>
          </div>
        </div>

        <a routerLink="/ueber-uns"><button
          [ngClass]="{'active-menupoint': router.url.startsWith('/ueber-uns')}"
          class="nav-button">Über uns</button></a>
      </div>

      <div *ngIf="!router.url.includes('company') && !router.url.includes('resources') && !router.url.includes('caseStudies') ">
        <a (click)="routeToTalentAgent()">
          <button class="blank-button-small" >Login</button>
        </a>

        <a (click)="routeToTalentAgentOb('navbar')">
          <button  class="accent-button ita_initiate-checkout ita_format-talentagent page-content-cta" >Registrieren</button>
        </a>
      </div>

      <div *ngIf="router.url.includes('company') || router.url.includes('resources') || router.url.includes('caseStudies') " class="flex gap row center">
        <a (click)="routeToCompanyLogin()">
          <button class="blank-button-small">Login</button>
        </a>

        <a (click)="routeToDemoDesk('Navbar')">
          <button  class="accent-button">Demo buchen</button>
        </a>
      </div>
    </div>

    <div id="navbar-mobile" class="flex flex-space-between-center center" *ngIf="mediaService.getMaxWidthQuery(1200).matches">
      <img src="assets/images/talentagent-logo.png" alt="">
      <burger-menu></burger-menu>
    </div>
  </div>















  <div id="content-wrapper">
    <router-outlet></router-outlet>
  </div>

  <footer id="footer" class="flex column center">
    <div class="flex column gap-lg content-width width-100">
      <div
        [ngClass]="{'padding-vertical-lg': !mediaService.isMobile()}"
        class="flex row-wrap flex-space-between gap-xl">
        <div class="flex row space-around gap-lg" [ngClass]="{'row': !mediaService.isTablet(), 'row-wrap': mediaService.isTablet()}">
          <div class="flex column gap" [ngClass]="{
        'width-33': !mediaService.isTablet() && !router.url.includes('demo-booking') && showNavbar,
        'width-66': !mediaService.isTablet() && (router.url.includes('demo-booking') || !showNavbar),
        'width-100': mediaService.isTablet()}">
            <img src="assets/images/talentagent-logo.png" alt="">
            <div class="footer-text">
            <span *ngIf="router.url.includes('talent') || router.url.includes('guides') ||
            router.url.includes('tp') || router.url.includes('use-regulation')">
              Finde deinen Einstiegsjob nach dem Studium mit Talentagent, der innovativen Jobplattform von Uniwunder.
            Mit einer Vielzahl von Stellenangeboten helfen wir dir, den Traumjob zu finden und mit ausschließlich passenden Angeboten deine Jobsuche zu erleichtern.
            </span>
              <span *ngIf="router.url.includes('company') || router.url.includes('webinar') || router.url.includes('caseStudies') ||
            router.url.includes('datenschutz') || router.url.includes('imprint')">
              Erhalte nur geprüfte Talente für deine Einstiegsjobs mit Talentagent, der führenden Job-Plattform für Uni-Absolventen.
                Reduziere deinen Aufwand und spare Zeit durch unser Qualitätsversprechen.
                Schalte beliebig viele Stellenanzeigen ohne Laufzeit mit garantierten gebuchten Anzahl an Juniorbewerbungen.
            </span>
              <span *ngIf="router.url.includes('demo-booking')">
              Erhalte nur geprüfte Talente für deine Einstiegsjobs mit Talentagent, der führenden Job-Plattform für Uni-Absolventen.
                Reduziere deinen Aufwand und spare Zeit durch unser Qualitätsversprechen.
                Schalte beliebig viele Stellenanzeigen ohne Laufzeit mit garantierten gebuchten Anzahl an Juniorbewerbungen.
            </span>
            </div>
          </div>

          <div class="flex row-wrap flex-space-between" [ngClass]="{'gap-xl': !mediaService.isMobile(), 'gap-lg': mediaService.isMobile()}">
            <div *ngIf="!router.url.includes('demo-booking') && showNavbar" class="flex column gap">
              <h3 class="small-primary-header color-primary">Für Studenten</h3>
              <a [ngClass]="{'dark-grey-active-anchor': router.url.includes('talent') && !router.url.includes('talent/')}"
                 class="dark-grey-anchor" routerLink="/talent">Infos für Studenten</a>
              <a
                [ngClass]="{'dark-grey-active-anchor': router.url.includes('talent/karriereguide')}"
                class="dark-grey-anchor" routerLink="/talent/karriereguide">Karriereguide</a>
              <a class="dark-grey-anchor" (click)="routeToTalentAgent()">Anmelden</a>
              <a class="dark-grey-anchor" (click)="routeToTalentAgentOb('footer')">Registrieren</a>
            </div>

            <div *ngIf="!router.url.includes('demo-booking') && showNavbar" class="flex column gap">
              <h3 class="small-primary-header color-primary">Für Unternehmen</h3>
              <a
                [ngClass]="{'dark-grey-active-anchor': router.url.includes('/company') && !router.url.includes('company/')}"
                class="dark-grey-anchor" routerLink="/company">Infos für Unternehmen</a>
              <a
                [ngClass]="{'dark-grey-active-anchor': router.url.includes('/hr-insights-hub') && !router.url.includes('company/hr-insights/')}"
                class="dark-grey-anchor" routerLink="/company/hr-insights-hub">HR-Insights Hub</a>
              <a class="dark-grey-anchor" (click)="routeToDemoDesk('Footer')">Demo Vereinbaren</a>
              <a class="dark-grey-anchor" (click)="routeToCompanyLogin()">Login</a>
              <a class="dark-grey-anchor" (click)="routeToCompanyOnboarding()">Unternehmensaccount anlegen</a>
            </div>

            <div *ngIf="!router.url.includes('demo-booking') && showNavbar" class="flex column gap">
              <h3 class="small-primary-header color-primary">Links</h3>
              <a class="dark-grey-anchor" (click)="routeToUniwunder()">Uniwunder</a>
              <a class="dark-grey-anchor" (click)="routeToHID()">Hochschulinitiative</a>
            </div>

          </div>


        </div>

      </div>

      <div class="divider"></div>

      <div class="flex gap-lg flex-space-between-center" [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}">
        <div class="flex gap-lg" [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}">
          <div class="center-text">Copyright &#169; 2024 Uniwunder</div>

          <div class="flex row-wrap center gap-lg">
            <a class="black-anchor" href="/datenschutz" target="_blank">Datenschutz</a>

            <a class="black-anchor" href="/imprint" target="_blank">Impressum</a>

            <a *ngIf="agbLink" class="black-anchor" [href]="agbLink" target="_blank">AGB</a>

            <a class="black-anchor" href="/support" target="_blank">Kontakt</a>

            <a class="black-anchor" href="/ueber-uns" target="_blank">Über Uns</a>
          </div>

        </div>

        <div class="flex row gap start-center">

          <a (click)="routeToSocialMedia('https://tiktok.com/@hochschulinitiative_uw')">
            <button class="primary-icon-button">
              <i class="fa-brands fa-tiktok"></i>
            </button>
          </a>

          <a (click)="routeToSocialMedia('https://www.linkedin.com/company/talentagent-uw')">
            <button class="primary-icon-button">
              <i class="fa-brands fa-linkedin-in"></i>
            </button>
          </a>


          <a (click)="routeToSocialMedia('https://www.facebook.com/hochschulinitiative')">
            <button class="primary-icon-button">
              <i class="fa-brands fa-facebook-f"></i>
            </button>
          </a>


          <a (click)="routeToSocialMedia('https://www.instagram.com/hochschulinitiativedeutschland/')">
            <button class="primary-icon-button">
              <i class="fa-brands fa-instagram"></i>
            </button>
          </a>


        </div>
      </div>
    </div>

  </footer>


  <a href="company/hr-insights-hub" style="display:none"></a>
</div>

