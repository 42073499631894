import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {MediaService} from "../../../utility/services/media.service";
import {videoData} from "../videos";

@Component({
  selector: 'video-preview',
  templateUrl: './video-preview.component.html',
  styleUrl: './video-preview.component.scss'
})
export class VideoPreviewComponent {
  @Input() previewUrl: string;
  @Input() videoUrl: string;

  @Input() index: number;


  constructor(
    private media: MediaService,
  ) {
  }

  playVideo(videoElement: HTMLVideoElement) {
    // video doesn't play on IOS
    if (!this.media.isIOS()) {
      const overlay = videoElement.previousElementSibling as HTMLElement;

      videoElement.currentTime = 0;
      videoElement.play().then(() => overlay.classList.add('hidden'));
      videoElement.onended = () => {
        overlay.classList.remove('hidden');
      };
    }
  }

  stopVideo(videoElement: HTMLVideoElement) {
    if (!this.media.isIOS()) {
      const overlay = videoElement.previousElementSibling as HTMLElement;

      overlay.classList.remove('hidden');
      videoElement.pause();
      videoElement.currentTime = 0;
    }
  }

  protected readonly videoData = videoData;
}
