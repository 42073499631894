<div class="width-100 flex center column">

    <section class="flex column center width-100 header-section overflow-clip header-gradient">
      <div
        class="flex row flex-space-between content-width"
        [ngClass]="{'width-80': !mediaService.getMaxWidthQuery(1000).matches, 'width-100': mediaService.getMaxWidthQuery(1000).matches}">
        <div
          [ngClass]="{'center-start': mediaService.getScreenWidth() > 1000,
            'width-50': mediaService.getScreenWidth() > 1000,
            'padding-vertical-xl': mediaService.getScreenWidth() > 1000,
            'width-100': mediaService.getScreenWidth() <= 1000}"
          class="flex column z-index-1">
          <p
            [ngClass]="{'width-100': mediaService.getScreenWidth() > 1000}"
            class="color-accent bold-text">Von der Uni zum Traumjob</p>
          <h1 class="page-header">
            Die Nr. 1 <br>
            Jobplattform <br *ngIf="mediaService.getScreenWidth() <= 1000"> für <br>
            <span class="highlight-white no-wrap-text">Uni-Absolventen</span>
          </h1>
          <p class="regular-text margin-bottom width-66" [ngClass]="{'margin-vertical': mediaService.getScreenWidth() > 1000}">
            Jobsuche für Berufseinsteiger<br *ngIf="mediaService.getScreenWidth() < 500"> muss nicht stressig sein
          </p>
          <a (click)="routeToTalentAgent('header')" *ngIf="mediaService.getScreenWidth() > 1000">
            <button  class="accent-button ita_initiate-checkout ita_format-talentagent page-content-cta">Kostenlos anmelden</button>
          </a>
          <div *ngIf="mediaService.getScreenWidth() <= 1000" class="center column flex width-100 margin-top">
            <a (click)="routeToTalentAgent('header')">
              <button class="accent-button ita_initiate-checkout ita_format-talentagent page-content-cta">Kostenlos anmelden</button>
            </a>
          </div>
        </div>

        <div *ngIf="mediaService.getScreenWidth() > 1200" class="header-image-container">
<!--          <img class="header-image delay-0-2s perspective-blend-in" src="assets/images/laptop-talent.png" alt="">-->
          <div class="header-image width-100 flex row-wrap-start-end row-wrap-gap-sm gap-sm">
            <i class="mdi mdi-creation header-stars-1 perspective-blend-in"></i>
            <div class="flex perspective-blend-in opacity-0">
              <div class="image-label-1">
                <i class="mdi mdi-briefcase-outline"></i>
                Keine Berufserfahrung notwendig
              </div>
              <img class="header-image-card image-card-1" src="assets/images/student-blue-wall.png" alt="">
            </div>
            <div class="flex perspective-blend-in delay-0-2s opacity-0">
              <div class="image-label-2">
                <i class="mdi mdi-school-outline"></i>
                Einstiegsjobs für Absolventen
              </div>
              <img class="header-image-card image-card-2" src="assets/images/students-smiling.jpg" alt="">
            </div>
            <div class="flex perspective-blend-in delay-0-4s opacity-0">
              <div class="image-label-3">
                <i class="mdi mdi-file-document-outline"></i>
                Ohne Anschreiben bewerben
              </div>
              <img class="header-image-card image-card-3" src="assets/images/blue-hair-student.jpg" alt="">
            </div>
            <i class="mdi mdi-creation header-stars-2 perspective-blend-in delay-0-4s"></i>
          </div>
        </div>
      </div>

      <img *ngIf="mediaService.getScreenWidth() <= 1200" class="mobile-header-image" src="assets/images/mobile-header-student.png" alt="">

    </section>

  <div class="content-width">

    <section
      *ngIf="mediaService.getScreenWidth() > 1000"
      class="flex column center padding-vertical-lg">
      <div class="flex width-100"
           [ngClass]="{'padding-horizontal-xl': mediaService.getScreenWidth() > 1200,
           'padding-horizontal': mediaService.getScreenWidth() <= 1200
           }">
        <div class="grid width-100">
          <div class="flex usp grid-item-3">
            <div class="flex column start card-flat width-100">
              <div class="padding width-100">
                <div class="padding-vertical-lg flex row flex-space-between-center width-100">
                  <div>
                    <h2 class="color-accent usp-number">5.300+</h2>
                  </div>
                  <div>
                    <img src="assets/images/handshake.png" alt="">
                  </div>
                </div>
                <p>Jobangebote findet ihr auf Talentagent</p>
              </div>
            </div>
          </div>

          <div class="flex usp delay-0-4s grid-item-3">
            <div class="flex column start card-flat width-100">
              <div class="padding width-100">
                <div class="padding-vertical-lg flex row flex-space-between-center width-100">
                  <div>
                    <h2 class="peach-text usp-number">0</h2>
                  </div>
                  <div>
                    <img src="assets/images/star-row.png" alt="">
                  </div>
                </div>
                <p>Berufserfahrung erforderlich, um sich zu bewerben</p>
              </div>
            </div>
          </div>

          <div class="flex usp delay-0-2s grid-item-3">
            <div class="flex column start card-flat width-100">
              <div class="padding width-100">
                <div class="padding-vertical-lg flex row flex-space-between-center width-100">
                  <div>
                    <h2 class="color-teal usp-number">100k+</h2>
                  </div>
                  <div>
                    <img src="assets/images/job-click.png" alt="">
                  </div>
                </div>
                <p>Absolventen haben hier nach ihrem Job gesucht</p>
              </div>
            </div>
          </div>

          <div class="flex usp delay-0-6s grid-item-3">
            <div class="flex column start card-flat width-100">
              <div class="padding width-100">
                <div class="padding-vertical-lg flex row flex-space-between-center width-100">
                  <div>
                    <h2 class="violet-text usp-number">46k</h2>
                  </div>
                  <div>
                    <img src="assets/images/party-pinata.png" alt="">
                  </div>
                </div>
                <p>Bewerbungen wurden über uns verschickt</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section *ngIf="mediaService.getScreenWidth() <= 1000"
             class="flex column center gap"
             [ngClass]="{'padding-vertical-lg': mediaService.isMobile(), 'padding-lg': !mediaService.isMobile()}">

      <div class="grid usp card-sharp-shadow width-100">
        <span class="grid-item-3 flex center huge-text">🤝</span>

        <div class="grid-item-9 usp-text-mobile">
          <span class="color-accent extra-bold-text">5.300+</span>
          Jobangebote findet ihr auf Talentagent
        </div>

      </div>

      <div class="grid usp card-sharp-shadow width-100">
        <span class="grid-item-3 flex center huge-text">💼</span>

        <div class="grid-item-9 usp-text-mobile">
          <span class="color-accent extra-bold-text">0</span>
          Berufserfahrung erforderlich, um sich zu bewerben
        </div>

      </div>

      <div class="grid usp card-sharp-shadow width-100">
        <span class="grid-item-3 flex center huge-text">👩‍🎓</span>

        <div class="grid-item-9 usp-text-mobile">
          <span class="color-accent extra-bold-text">100k+</span>
          Absolventen suchten hier nach ihrem Traumjob
        </div>

      </div>

      <div class="grid usp card-sharp-shadow width-100">
        <span class="grid-item-3 flex center huge-text">🎉</span>

        <div class="grid-item-9 usp-text-mobile">
          <span class="color-accent extra-bold-text">46k</span>
          Bewerbungen wurden über uns verschickt
        </div>

      </div>

    </section>

    <section
      [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile(), 'padding-horizontal': mediaService.isMobile()}"
      class="flex center column">
      <br *ngIf="mediaService.isMobile()">
      <h2 class="big-section-header margin-top">
        <span class="highlight">4 Gründe</span> dich bei uns anzumelden
      </h2>
      <div
        [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}"
        class="flex center width-100 padding-vertical-lg">
        <div
          [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
          class="flex column center  padding-lg">
          <div class="flex center-start column content-box-width">
            <div class="flex">
              <div class="white-gradient-overlay"></div>
              <p class="step-text color-primary remove-margin">01</p>
            </div>
            <h3 class="remove-margin">Lost nach dem Studium?</h3>
            <p class="regular-text">
              Uni abgeschlossen und unsicher, was als Nächstes kommt?  Gib uns einfach deinen Studiengang und Wunschbranche an.
              Wir finden passende Einstiegsjobs  für dich und unterstützen bei der Suche.</p>
          </div>

        </div>

        <div
          [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
          class="padding-lg flex center">
          <div class="teal-container width-100 step1-image-wrapper">
            <img class="talent-image-1 hidden delay-0-2s" src="assets/images/talent-jobsearch.png" alt="">
            <img class="question-marks appearCenterToTopRight delay-0-4s" src="assets/images/question-marks.png" alt="">
          </div>
        </div>
      </div>

    </section>


    <section
      [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile(), 'padding-horizontal': mediaService.isMobile()}"
      class="flex center column hidden">

      <div class="flex center width-100">
        <img class="arrow-img arrow reveal-image" src="assets/images/arrow.png" alt="">
      </div>

      <div
        [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}"
        class="flex center width-100 hidden padding-vertical-lg">
        <div
          *ngIf="!mediaService.isMobile()"
          class="padding-lg width-50 flex center background-light-peach round-background">
          <img class="cv-img delay-0-2s" src="assets/images/talent-cv.png" alt="">
          <img class="magnify-icon delay-0-5s" src="assets/images/magnigy.png" alt="">
          <img class="mlp-small-logo" src="assets/images/mlp-small-logo.png" alt="">
        </div>

        <div
          [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
          class="flex column center padding-lg">
          <div class="flex center-start column content-box-width">
            <div class="flex">
              <div class="white-gradient-overlay"></div>
              <p class="step-text color-peach remove-margin">02</p>
            </div>
            <h3 class="remove-margin">Kostenloser Lebenslaufcheck</h3>
            <p class="regular-text">
              Optimiere deinen Lebenslauf und erhöhe deine Chancen auf ein Bewerbungsgespräch!
              Aber das ist noch nicht alles: Wir haben
              <strong>Karriere-Experten</strong> die dich bei all deinen Fragen unterstützen,
              damit du bestmöglichst für deine Bewerbungsgespräche vorbereitet bist.
            </p>
          </div>
        </div>

        <div
          *ngIf="mediaService.isMobile()"
          class="padding-lg width-100 flex center background-light-peach round-background">
          <img class="cv-img delay-0-2s" src="assets/images/talent-cv.png" alt="">
          <img class="magnify-icon delay-0-4s" src="assets/images/magnigy.png" alt="">
          <img class="mlp-small-logo" src="assets/images/mlp-small-logo.png" alt="">
        </div>
      </div>

    </section>

    <section
      [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile(), 'padding-horizontal': mediaService.isMobile()}"
      class="flex center column">

      <div class="flex center width-100">
        <img class="arrow-img-flip arrow reveal-image" src="assets/images/arrow.png" alt="">
      </div>

      <div
        [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}"
        class="flex center row width-100 padding-vertical-lg">
        <div
          [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
          class="flex column center padding-lg">
          <div class="flex center-start column content-box-width">
            <div class="flex">
              <div class="white-gradient-overlay"></div>
              <p class="step-text color-primary remove-margin">03</p>
            </div>
            <h3 class="remove-margin">Wir wissen was zu dir passt</h3>
            <p class="regular-text">
              Mit Talentagent brauchst du dich nicht mit endlosen Stellensuchen herumschlagen.
              Lade einfach deinen Lebenslauf hoch und lass uns die Arbeit machen!
              Unser smartes System scannt deine Skills & Interessen und matcht sie mit passenden Stellenangeboten.
            </p>
          </div>
        </div>

        <div
          [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
          class="padding-lg flex center teal-container top-match-container">
          <img class="big-sparkles delay-0-5s" src="assets/images/sparkles.png" alt="">
          <img class="little-sparkles delay-0-5s" src="assets/images/sparkles.png" alt="">
          <div class="top-match-pseudo-button delay-0-2s flex">
            Top-Match
            <img class="click-hand delay-0-2s" src="assets/images/tabler_hand-click.png" alt="">
          </div>
        </div>
      </div>
    </section>

    <section
      [ngClass]="{'padding-horizontal-xl': !mediaService.isMobile(), 'padding-horizontal': mediaService.isMobile()}"
      class="flex center column">

      <div class="flex center width-100">
        <img class="arrow-img arrow reveal-image" src="assets/images/arrow.png" alt="">
      </div>

      <div
        [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}"
        class="flex center width-100 padding-vertical-lg">
        <div
          *ngIf="!mediaService.isMobile()"
          class="padding-lg width-50 flex center background-light-peach last-step-container overflow-hidden">
          <img class="talent-over-shoulder" src="assets/images/talent-over-shoulder.png" alt="">
          <img class="file-marine" src="assets/images/marine-file.png" alt="">
          <img class="cancel-teal delay-0-2s" src="assets/images/teal-cancel.png" alt="">
        </div>

        <div
          [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
          class="flex column center padding-lg">
          <div class="flex center-start column content-box-width">
            <div class="flex">
              <div class="white-gradient-overlay"></div>
              <p class="step-text color-peach remove-margin">04</p>
            </div>
            <h3 class="remove-margin">Anschreiben adé</h3>
            <p class="regular-text">
              Das Ding mit dem Anschreiben?
              Das war gestern. Bei Talentagent brauchst du dir darüber keinen Kopf mehr zu zerbrechen.
              Lade deinen Lebenslauf einmalig hoch und bewirb dich mit nur wenigen Klicks für deinen Traumjob.
            </p>
          </div>
        </div>

        <div
          *ngIf="mediaService.isMobile()"
          class="padding-lg width-100 flex center background-light-peach last-step-container overflow-hidden">
          <img class="talent-over-shoulder" src="assets/images/talent-over-shoulder.png" alt="">
          <img class="file-marine" src="assets/images/marine-file.png" alt="">
          <img class="cancel-teal delay-0-2s" src="assets/images/teal-cancel.png" alt="">
        </div>
      </div>

      <a (click)="routeToTalentAgent('in-text-1')">
        <button class="accent-button ita_initiate-checkout ita_format-talentagent page-content-cta">Kostenlos anmelden</button>
      </a>
    </section>

    <br>
    <br>

    <section class="padding-lg flex center column">
      <h2 class="big-section-header" [ngClass]="{'margin-bottom-lg': mediaService.isMobile()}">Unsere <br *ngIf="mediaService.getScreenWidth() < 500"><span class="highlight">aktuellen</span> Stellen</h2>
      <div
        class="jobs "
        [ngClass]="{'grid': !mediaService.isMobile(),
        'width-100': mediaService.isMobile(),
        'padding-horizontal-xl': !mediaService.isMobile(),
        'margin-top': !mediaService.isMobile(),
        'flex': mediaService.isMobile(), 'row': mediaService.isMobile(), 'horizontal-scrollable-container': mediaService.isMobile()}">
        <div *ngFor="let position of displayJobs; let i = index"
             [ngClass]="{'grid-item-4': !mediaService.isMobile(), 'delay-0-2s': i == 1, 'delay-0-4s': i == 2}"
             class="padding-lg">
          <app-employment-position-card [positionData]="position"></app-employment-position-card>
        </div>
      </div>
      <p class="padding-vertical color-marine bold-text width-100 center-text" style="font-size: 1.25rem">Derzeit über 5.300 Stellen verfügbar!</p>
      <a (click)="routeToTalentAgent('in-text-2')">
        <button class="accent-button ita_initiate-checkout ita_format-talentagent page-content-cta">Kostenlos anmelden</button>
      </a>
    </section>

    <section *ngIf="mediaService.getScreenWidth() > 1000" class="flex row-wrap center padding-vertical-xl width-100">
      <div
        [ngClass]="{'margin-vertical': mediaService.isMobile()}"
        class="flex column center filter-grayscale padding-horizontal partner-logo">
        <img src="assets/images/partner-logos/Curacon.jpg" alt=""></div>
      <div
        [ngClass]="{'margin-vertical': mediaService.isMobile()}"
        class="flex smaller-logo column center filter-grayscale padding-horizontal partner-logo delay-0-2s">
        <img src="assets/images/partner-logos/MLP.png" alt=""></div>
      <div
        [ngClass]="{'margin-vertical': mediaService.isMobile()}"
        class="flex smaller-logo column center filter-grayscale padding-horizontal partner-logo delay-0-4s">
        <img src="assets/images/partner-logos/Consileon.png" alt=""></div>
      <div
        [ngClass]="{'margin-vertical': mediaService.isMobile()}"
        class="flex column center filter-grayscale padding-horizontal partner-logo delay-0-6s">
        <img src="assets/images/partner-logos/protema.png" alt=""></div>
      <div
        [ngClass]="{'margin-vertical': mediaService.isMobile()}"
        class="flex column center filter-grayscale padding-horizontal partner-logo delay-0-8s">
        <img src="assets/images/partner-logos/b-k-p.jpg" alt=""></div>
    </section>

  </div>

  <section
    [ngClass]="{'padding-xl': !mediaService.isMobile(), 'padding': mediaService.isMobile()}"
    class="flex column width-100 center background-light-teal">

    <div
      [ngClass]="{'padding-xl': !mediaService.isMobile(), 'padding-lg': mediaService.isMobile()}"
      class="background-white mlp-section">
      <div class="column flex mlp-content-block">
        <div class="flex row start-center">
          <h2 class="color-marine margin-right">Dein persönlicher <span class="color-primary">Karriere-Experte</span></h2>
          <img class="rocket-icon" src="assets/images/rocket-img.png" alt="">
        </div>
        <p class="regular-text padding-vertical" [ngClass]="{'width-100': mediaService.isTablet(), 'width-80': !mediaService.isTablet()}">
          Während deines Karrierestarts erhältst du von den Experten unseres Partners MLP die volle Unterstützung
          - und das komplett kostenlos! Von den folgenden optionalen Benefits kannst du profitieren:</p>

        <div
          [ngClass]="{
          'row': !mediaService.isMobile(),
          'start': mediaService.isMobile(),
          'center': !mediaService.isMobile(),
          'padding-vertical-lg': !mediaService.isMobile(),
          'column': mediaService.isMobile()}"
          class="flex  ">
          <div class="flex row center padding-sm icon-blob-vertical">
            <i class="mdi mdi-file margin-right"></i> Lebenslauf-Check
          </div>

          <div class="flex row center padding-sm icon-blob-vertical">
            <i class="mdi mdi-cash margin-right"></i> Einstiegsgehaltsberatung
          </div>

          <div class="flex row center padding-sm icon-blob-vertical">
            <i class="mdi mdi-account-multiple margin-right"></i> Potenzialanalyse mit AECDisk
          </div>
        </div>

        <p class="regular-text padding-vertical" [ngClass]="{'width-100': mediaService.isMobile(), 'width-80': !mediaService.isMobile()}">
          Bei unserem Gesamtangebot werden wir von MLP als Partner unterstützt,
          welche die Rolle der Karriere-Experten einnehmen: Ausgewählte MLP Berater,
          die über eine Qualifizierung an der MLP Corporate University verfügen,
          unterstützen dich als Bewerber optional mit fundiertem Erfahrungswissen.
          Dabei werden deine Fragen rund um das Thema Berufseinstieg beantwortet und Hilfestellungen angeboten.
          Nachdem du durch Talentagent den Job erhalten hast, könnten sich bei dir weitere Fragen im Bereich Finanzen ergeben.
          Dein MLP-Berater steht weiterhin zur Verfügung,
          damit du langfristig von den Fachkenntnissen der Finanzexperten profitieren kannst.
          <br>
          <span class="color-marine ">Mehr Informationen zu MLP findest du</span> <a (click)="openMlpInformationDialog()"> hier</a>
        </p>
        <img class="mlp-small-logo" src="assets/images/mlp-small-logo.png" alt="">
        <br class="margin-bottom-lg">
      </div>

      <img *ngIf="!mediaService.isMobile()" class="coach-image" src="assets/images/career-coach.png" alt="">
    </div>

  </section>



  <section  class="flex padding-lg column center cap-width width-100">
    <h2 class="big-section-header">Karriere <span class="highlight">Guides</span></h2>
    <div class="flex padding-vertical row cap-width guides horizontal-scrollable-container width-100"
         [ngClass]="{'start': mediaService.isMobile(), 'center': !mediaService.isMobile(), 'gap-lg': !mediaService.isMobile()}">
      <div class="padding" *ngFor="let guide of careerGuides; let i = index" [ngClass]="{'delay-0-2s': i == 1, 'delay-0-4s': i == 2}">
        <app-career-guide-card [careerGuide]="guide"></app-career-guide-card>
      </div>
    </div>
    <a routerLink="/talent/karriereguide">
      <button class="accent-button margin-top">Zu den Karriereguides</button>
    </a>
  </section>


  <!--section
    [ngClass]="{'padding-xl': !mediaService.isMobile(), 'padding': mediaService.isMobile()}"
    class="flex column center width-100 background-light-teal">
    <h2 class="big-section-header">Was unsere <span class="highlight">Talente</span> sagen</h2>
    <div
      [ngClass]="{'padding-xl': !mediaService.isMobile(), 'padding': mediaService.isMobile()}"
      class="flex row width-100 horizontal-scrollable-container">
      <app-testimonial-card></app-testimonial-card>
      <app-testimonial-card></app-testimonial-card>
      <app-testimonial-card></app-testimonial-card>
      <app-testimonial-card></app-testimonial-card>
      <app-testimonial-card></app-testimonial-card>
    </div>
  </section-->

</div>
