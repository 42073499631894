import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgForOf, NgIf } from '@angular/common';
import { CompanyHrInsightsModule } from '../company-hr-insights.module';
import { videoData } from '../videos';
import {delay} from "../../../utility/services/utils.service";

@Component({
  selector: 'app-report',
  standalone: true,
  imports: [NgIf, CompanyHrInsightsModule, NgForOf],
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  protected readonly videoData = videoData;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {

  }

}
