import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit,} from '@angular/core';
import {MediaService} from "../../../utility/services/media.service";
import {MetaService} from "../../../utility/services/meta.service";
import {MetaDefinition, Title} from "@angular/platform-browser";
import {videoData} from "../videos";

@Component({
  selector: 'app-company-insights-page',
  templateUrl: './company-insights-page.component.html',
  styleUrl: './company-insights-page.component.scss'
})
export class CompanyInsightsPageComponent implements OnInit, AfterViewInit, OnDestroy {
  videos = videoData;

  constructor(
    public mediaService: MediaService,
    private metaService: MetaService,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title
  ) {
  }

  ngOnDestroy(): void {
    this.titleService.setTitle("Talentagent - Einfacher Berufseinstieg für Absolventen!");
  }

  ngOnInit() {
    this.addMetaTags()
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges()
  }


  addMetaTags() {
    let descriptionTag: MetaDefinition = {
      name: 'description',
      content: 'Erhalte nur geprüfte Bewerber, spare Zeit und Ressourcen durch unsere Qualitätsgarantie für eine effiziente Talentgewinnung! ',
    }

    this.metaService.setMetaTag(descriptionTag)

    this.titleService.setTitle("Talentagent: Nr. 1 Jobplattform für Uni-Absolventen");

    this.metaService.setThemeColor('#b3e0dc');
  }
}
