import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {CareerguideContentData, TalentPositionPreviewData, PagedData, CarerrguideContentSearchFilterData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentCareerguideController
 */

@Injectable()
@ResourceParams({})
export class TalentCareerguideResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/careerguide/content/find',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getContentByFilter: IResourceMethodObservableStrict<CarerrguideContentSearchFilterData, null, null, PagedData<CareerguideContentData>> | undefined
    getContentByFilter(requestBody: CarerrguideContentSearchFilterData): Promise<PagedData<CareerguideContentData>> {
        if (!this._getContentByFilter) throw new Error("resource has not been properly initialized")
        return this._getContentByFilter(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/careerguide/topArticles',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTopArticles: IResourceMethodObservableStrict<null, null, null, CareerguideContentData[]> | undefined
    getTopArticles(): Promise<CareerguideContentData[]> {
        if (!this._getTopArticles) throw new Error("resource has not been properly initialized")
        return this._getTopArticles(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/careerguide/lastSearchPositions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getUpsellPositions: IResourceMethodObservableStrict<null, null, null, TalentPositionPreviewData[]> | undefined
    getUpsellPositions(): Promise<TalentPositionPreviewData[]> {
        if (!this._getUpsellPositions) throw new Error("resource has not been properly initialized")
        return this._getUpsellPositions(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/careerguide/visitedVisitedCareerguide',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _reportTalentVisitedCareerguide: IResourceMethodObservableStrict<null, null, null, null> | undefined
    reportTalentVisitedCareerguide(): Promise<null> {
        if (!this._reportTalentVisitedCareerguide) throw new Error("resource has not been properly initialized")
        return this._reportTalentVisitedCareerguide(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/careerguide/talentVisitedContent',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _reportTalentVisitedContent: IResourceMethodObservableStrict<null, {url: string}, null, null> | undefined
    reportTalentVisitedContent(requestParams: {url: string}): Promise<null> {
        if (!this._reportTalentVisitedContent) throw new Error("resource has not been properly initialized")
        return this._reportTalentVisitedContent(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/careerguide/search',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _searchAndTrackElasticSearchRequest: IResourceMethodObservableStrict<CarerrguideContentSearchFilterData, {pageSize?: number, pageIndex?: number}, null, PagedData<CareerguideContentData>> | undefined
    searchAndTrackElasticSearchRequest(requestBody: CarerrguideContentSearchFilterData, requestParams?: {pageSize?: number, pageIndex?: number}): Promise<PagedData<CareerguideContentData>> {
        if (!this._searchAndTrackElasticSearchRequest) throw new Error("resource has not been properly initialized")
        return this._searchAndTrackElasticSearchRequest(requestBody, requestParams || {}, null).toPromise()
    }

}