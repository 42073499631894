<section class="header">
  <div class="hero">
    <div class="hero-left">
      <h2>Nice to meet you!</h2>
      <h1>Wir sind Talentagent</h1>
      <p>Die Jobplattform von Uniwunder, die Studis und Absolventen hilft, den Einstieg ins Berufsleben easy zu meistern.
        Von der Jobsuche bis zum Gespräch – wir sorgen dafür, dass der Karrierestart stressfrei und selbstbewusst gelingt.
        Gleichzeitig profitieren Unternehmen von erhöhter Sichtbarkeit und passgenauen Bewerbungen – für eine effiziente Talentgewinnung.</p>
    </div>
    <div class="hero-right">
      <img class="team"  src="assets/images/about-us/team.png" alt="Das Team">
      <img class="sun" src="assets/images/about-us/icons/sun.svg" alt="">
    </div>
  </div>
</section>

<section class="metric-section">
  <div class="metric-wrapper">
    <div class="metric-display">
      <img class="icon" src="assets/images/about-us/icons/employees.png" alt="">
      <h3>40</h3>
      <p>Teammitglieder</p>
    </div>

    <div class="metric-display">
      <img class="icon" src="assets/images/about-us/icons/user.png" alt="">
      <h3>100.000</h3>
      <p>Talentagent-User</p>
    </div>

    <div class="metric-display">
      <img class="icon" src="assets/images/about-us/icons/location.png" alt="">
      <h3>Dresden</h3>
      <p>Location</p>
    </div>

    <div class="metric-display">
      <img class="icon" src="assets/images/about-us/icons/dog.png" alt="">
      <h3>4</h3>
      <p>Bürohunde</p>
    </div>
  </div>
</section>

<section class="student-to-student">
  <h3 class="📱-title">Von Studis für Studis 🎓</h3>
  <img class="s2s-image" src="assets/images/about-us/student-to-student.svg" alt="">
  <div class="s2s-text">
    <h3 class="🖥️-title">Von Studis für Studis 🎓</h3>
    <p>Als ehemalige Studierende wissen wir genau, wie es ist als Absolvent auf den Arbeitsmarkt zu treten und nicht
      genau zu wissen, wohin die Reise geht. Deshalb haben wir es uns zur Mission gemacht, Studierenden diese
      Unsicherheiten zu nehmen – mit einer Plattform, die nicht nur Jobs, sondern auch wertvolles Wissen und praxisnahes
      Know-how bietet. Bei uns müssen keine Anschreiben mitgeschickt werden, der Lebenslauf reicht völlig aus.
      Absolventen erhalten hilfreiche Guides und persönliche Unterstützung von Karriere-Experten, um stressfrei ihren
      ersten Job zu finden.</p>
  </div>
</section>


<section class="student-to-student2">
  <div class="s2s-text">
    <h3 class="🖥️-title">Der Treffpunkt für Absolventen und Unternehmen 💼</h3>
    <p>Über 60 % der Studierenden kennen uns – damit erreichen wir mehr Talente als klassische Jobportale. Talentagent
      ist die zentrale Schnittstelle, die Berufseinsteiger mit Recruiter auf der Suche nach Nachwuchskräften zusammenführt.
      Wir bieten eine erhöhte Sichtbarkeit der Stellenangebote und sorgen für ein optimales Match zwischen den
      Anforderungen der Unternehmen und den Wünschen der Top-Talente. So werden wertvolle Zeit und Ressourcen in der
      Job- und Kandidatensuche gespart  – eine echte Win-Win-Situation für beide Seiten!</p>
  </div>

  <div class="graduate-wrapper">
    <img class="graduate-image" src="assets/images/about-us/graduates.jpg" alt="">
  </div>
  <h3 class="📱-title">Der Treffpunkt für Absolventen und Unternehmen 💼</h3>
</section>


<section class="vision">
  <div class="quote">
    <img class="quoting-icon" src="assets/images/about-us/icons/quote.png" alt="''">
    <h4>Unsere Mission für den perfekten Berufseinstieg</h4>
    <p>"Mit Talentagent ermöglichen wir Absolventinnen & Absolventen den bestmöglichen Einstieg ins Berufsleben.
      Auf unserer Plattform tragen wir nicht nur alle Einstiegsstellen auf dem deutschen Markt zusammen, sondern
      unterstützen auch mit Hilfestellungen und Content. Arbeitgebern bieten wir an, diese große Anziehungskraft zu
      nutzen, um ihre Stellen auf Talentagent zu präsentieren und so Zugang zu zahlreichen, motivierten Talenten zu
      erhalten."</p>
  </div>

  <div class="matze">
    <img class="matze-image" src="assets/images/about-us/Matze.png" alt="">
  </div>
</section>


<section class="milestones">
  <h3>Von der Idee bis hier: Unsere Meilensteine</h3>
  <div>
    <div class="milestone-wrapper">
      <div class="milestone founding">
        <div class="milestone-text">
          <div class="milestone-header">
            <h4 class="year">2016</h4>
            <p class="what-happened">Gründung</p>
            <p class="where-it-happened">Uniwunder</p>
          </div>
          <p class="information-about">Als Studierende erkannten wir die fehlenden Erfolgsfaktoren im Studium.
            So entstand Uniwunder zur Förderung des Potenzials junger Talente.</p>
        </div>
        <img class="milestone-svg" src="assets/images/about-us/founding.svg" alt="">
      </div>


      <div class="milestone starting">
        <div class="milestone-text">
          <div class="milestone-header">
            <h4 class="year">2017</h4>
            <p class="what-happened">Start</p>
            <p class="where-it-happened">Hochschulinitiative Deutschland</p>
          </div>
          <p class="information-about">Wir unterstützen Studierende mit Weiterbildungen, darunter Workshops zu
            Karrierethemen, Finanzen, Steuern und weiteren Zusatzangeboten.</p>
        </div>

        <img class="milestone-svg" src="assets/images/about-us/HID.svg" alt="">
      </div>

      <div class="milestone first-steps">
        <div class="milestone-text">
          <div class="milestone-header">
            <h4 class="year">2019</h4>
            <p class="what-happened">Erste Schritte zur Absolventen-Jobplattform</p>
          </div>
          <p class="information-about">Wegen der hohen Nachfrage nach Unterstützung bei Karrierethemen starten wir eine
            Jobplattform mit Stellenangeboten von Partnerunternehmen.</p>
        </div>
        <img class="milestone-svg" src="assets/images/about-us/first-steps-ta.svg" alt="">
      </div>


      <div class="milestone talentagent">
        <div class="milestone-text">
          <div class="milestone-header">
            <h4 class="year">2023</h4>
            <p class="what-happened">Start</p>
            <p class="where-it-happened">Talentagent</p>
          </div>
          <p class="information-about">Wir bringen unsere eigene Jobplattform auf den Markt, um die Jobsuche für
            Absolventen und die Talentgewinnung für Unternehmen zu vereinfachen.</p>
        </div>
        <img class="milestone-svg" src="assets/images/about-us/talentagent.svg" alt="">
      </div>
    </div>
  </div>
</section>


<section class="learn-more-section">
  <h3 class="learn-more">Du möchtest mehr über uns erfahren?</h3>
  <a class="cta" href="https://uniwunder.com/team/" target="_blank">Team kennenlernen</a>
  <img class="star" src="assets/images/about-us/icons/star.svg" alt="">
  <img class="stars" src="assets/images/about-us/icons/stars.svg" alt="">
  <img class="doggo" src="assets/images/about-us/icons/doggo.svg" alt="">
</section>


<section class="where-to-find">
  <div class="contact-details">
    <h3 class="where-to-find-heading">Wo du uns findest</h3>
    <div class="contacts">
      <p>
        <span class="icon">📍</span>
        <span>Bergstraße 29, <br>01069 Dresden, Deutschland</span>
      </p>
      <p>
        <span class="icon">📞</span>
        <a href="tel:+4935179903384">+49 (0) 351 799 033 84</a>
      </p>
      <p>
        <span class="icon">📧</span>
        <a href="mailto:info@uniwunder.com">info&#64;uniwunder.com</a>
      </p>
    </div>

  </div>
  <div class="location-images-wrapper">
    <div class="location-images">
      <img class="house" src="assets/images/about-us/villa.png" alt="">
      <img class="certificate" src="assets/images/about-us/irgendeineAuszeichnung.png" alt="">
      <img class="christmas" src="assets/images/about-us/christmasUniwunder.png" alt="">
    </div>
  </div>
</section>



<section class="cta-backgrounds">
  <div class="student-background">
    <div class="student-wrapper">
      <p class="student">Studenten🎓</p>
      <p class="cta-text">Du bist Absolvent und suchst einen Job nach der Uni?</p>
      <a class="left-cta" (click)="routeToTalentAgentOb('aboutUs')">Kostenlos anmelden</a>
    </div>
  </div>

  <div class="company-background">
     <div class="company-wrapper">
       <p class="student">Unternehmen 💼</p>
       <p class="cta-text">Du bist ein Unternehmen auf der Suche nach Junior-Talenten?</p>
       <a class="left-cta" (click)="routeToDemoDesk('aboutUs')">Demo buchen</a>
     </div>
  </div>
</section>
