import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {MediaService} from "./utility/services/media.service";
import {AnalyticsService} from "./utility/services/gdprconsent/analytics.service";
import {LocalStorageService} from "./utility/services/local-storage.service";
import {NavbarService} from "./utility/services/navbar.service";
import {UtmService} from "./utility/services/utm.service";
import {DemodeskService} from "./general/demo-booking/demodesk.service";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit{
  title = 'frontend-public-new';
  showNavbar = true;
  isPopupOpen = false;
  agbLink: string
    enviromentVar
  constructor(
    public mediaService: MediaService,
    public router: Router,
    private analyticsService: AnalyticsService,
    private localStorageService: LocalStorageService,
    private navbarService: NavbarService,
    private utmService: UtmService,
    private demodeskService: DemodeskService,
    private http: HttpClient
  ) {
      this.enviromentVar = environment
      this.utmService.startUtmParamsSubscription()

    //cannot use it with scrollPositionRestoration in app-routing module because of scrollToSection() in company.component.ts
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (!router.url.includes('scrollTo')) window.scrollTo(0, 0)
      }
    })
  }

    ngAfterViewInit(): void {
        this.analyticsService.setVarifyEventCookie()
        setInterval(() => {
            this.analyticsService.setVarifyEventCookie()
        }, 3000)
    }

  ngOnInit() {

    if (this.localStorageService.getGDPRConsent().consent_tiktok) this.localStorageService.addTikTokScriptToHead()
    if(this.localStorageService.getGDPRConsent().consent_hotjar) this.localStorageService.addHotjarScriptToHead()

    this.localStorageService.updateSessionTracking()
    this.navbarService.navBarStatus.subscribe((status: boolean) => {
      this.showNavbar = status
    })
      this.getLatestTermsAndConditions()

  }

    getLatestTermsAndConditions() {
        this.http.get(this.enviromentVar.apiUrl + '/public/termsAnsConditions/latest', {
            headers: new HttpHeaders(),
            responseType: 'blob',
            observe: 'response'
        }).subscribe((response: HttpResponse<Blob>) => {
            if (response.body.size > 0) {
                const b = new Blob([response.body], {type: 'application/pdf'});
                this.agbLink = URL.createObjectURL(b);
            }
        });
    }


    routeToSocialMedia(url: string) {
    window.open(url, "_blank")
  }

  routeToTalentAgent() {
    window.open(environment.talentAgentFrontendUrl + this.utmService.getUtmParamsAsString())
  }

  routeToTalentAgentOb(buttonId: string) {
    this.analyticsService.reportCheckoutTrackingEvent('InitiateCheckout', null, buttonId)
    window.open(environment.talentAgentFrontendUrl +  '/onboarding' + this.utmService.getUtmParamsAsString())
  }
  routeToCompanyLogin(){
    window.open(environment.companyFrontendUrl + this.utmService.getUtmParamsAsString())
  }

  routeToCompanyOnboarding() {
    this.analyticsService.reportCompanyOnboarding("Footer")
    window.open(environment.companyFrontendUrl +  '/onboarding' + this.utmService.getUtmParamsAsString())
  }

  routeToHID() {
    window.open("https://hochschulinitiative-deutschland.de/home")
  }

  routeToDemoDesk(context: string) {
      this.demodeskService.navToDemodesk(context)
  }

  routeToUniwunder() {
    window.open("https://uniwunder.com/")
  }

  togglePopupMenu() {
    this.isPopupOpen = !this.isPopupOpen;
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
    this.isPopupOpen = false;
  }

  @HostListener('document:click', ['$event'])
  closePopupMenu(event: Event): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.popup-menu-container')) {
      this.isPopupOpen = false;
    }
  }
}
