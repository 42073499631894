import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VideoPreviewComponent} from "./video-preview/video-preview.component";
import {CompanyInsightsPageComponent} from "./company-insights-page/company-insights-page.component";
import {VideoCardComponent} from "./video-card/video-card.component";
import {RouterLink} from "@angular/router";
import {CheckedPointComponent} from "./checked-point/checked-point.component";
import {NextVideoComponent} from "./next-video/next-video.component";
import {VideoOverviewPageComponent} from "./video-overview-page/video-overview-page.component";
import {UtilityModule} from "../../utility/utility.module";


@NgModule({
  declarations: [
    VideoPreviewComponent,
    CompanyInsightsPageComponent,
    VideoCardComponent,
    VideoOverviewPageComponent,
    CheckedPointComponent,
    NextVideoComponent
  ],
  exports: [
    VideoPreviewComponent,
    CheckedPointComponent,
    NextVideoComponent
  ],
  imports: [
    CommonModule,
    RouterLink,
    UtilityModule
  ]
})
export class CompanyHrInsightsModule {
}
