import {Injectable, Injector} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {CookieService} from "./cookie.service";
import {LocalStorageService} from "../local-storage.service";
import {AnalyticsService} from "./analytics.service";
import {GDPRConsentDialogService} from "./gdprconsent-dialog.service";



@Injectable({
  providedIn: 'root'
})
export class GDPRConsentService {

private get router() {return this._injector.get(Router)}
private get dialogService() { return this._injector.get(GDPRConsentDialogService)}

  constructor(private _injector: Injector, private cookieService: CookieService,  private localStorageService: LocalStorageService,
              private analytics: AnalyticsService) {

}
init() {

  this.analytics.initGtm();

  this.router.events
    .subscribe(evt => {
        if ((evt instanceof NavigationEnd)) {

          //if user wasnt asked for consent before, ask him unless he currently enjoys the privacy agreement
          if (this.router.url.indexOf('datenschutz') === -1 && this.localStorageService.getGDPRConsent().initial) {
            const gdprConsent = this.cookieService.getGDPRConsentFromCookies()

            if (gdprConsent == null) this.dialogService.openConsentDialog(false);

            else this.localStorageService.updateGDPRConsent(gdprConsent)
          }

          this.analytics.reportVirtualPageView();
        }


  })
}
}
