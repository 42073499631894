import {Component, Input} from '@angular/core';
import {videoData} from "../videos";

@Component({
  selector: 'video-card',
  templateUrl: './video-card.component.html',
  styleUrl: './video-card.component.scss'
})
export class VideoCardComponent {

  @Input() index: number;

  protected readonly videoData = videoData;
}
