import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, TalentPositionDetailsData, TalentPositionPreviewData, RejectPositionProposalData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentPositionRelationController
 */

@Injectable()
@ResourceParams({})
export class TalentPositionRelationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/positionRelations/bookmark',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _bookmarkPositionProposal: IResourceMethodObservableStrict<null, {positionId: number, origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive' | 'Careerguide')}, null, null> | undefined
    bookmarkPositionProposal(requestParams: {positionId: number, origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive' | 'Careerguide')}): Promise<null> {
        if (!this._bookmarkPositionProposal) throw new Error("resource has not been properly initialized")
        return this._bookmarkPositionProposal(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/positionRelations/bookmarked/count',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _countBookmarkedPositionProposals: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number>> | undefined
    countBookmarkedPositionProposals(): Promise<JsonWrappedValue<number>> {
        if (!this._countBookmarkedPositionProposals) throw new Error("resource has not been properly initialized")
        return this._countBookmarkedPositionProposals(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/positionRelations/recommended/existsUnseen',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _existsUnseenRecommendation: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    existsUnseenRecommendation(): Promise<JsonWrappedValue<boolean>> {
        if (!this._existsUnseenRecommendation) throw new Error("resource has not been properly initialized")
        return this._existsUnseenRecommendation(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/positionRelations/bookmarked/positionIds',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _findBookmarkedPositionIds: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<number[]>> | undefined
    findBookmarkedPositionIds(): Promise<JsonWrappedValue<number[]>> {
        if (!this._findBookmarkedPositionIds) throw new Error("resource has not been properly initialized")
        return this._findBookmarkedPositionIds(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/recommendations',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getActiveTalentPositionRecommendations: IResourceMethodObservableStrict<null, {pageNum: number, pageSize: number, forcedIds?: number[]}, null, PagedData<TalentPositionPreviewData>> | undefined
    getActiveTalentPositionRecommendations(requestParams: {pageNum: number, pageSize: number, forcedIds?: number[]}): Promise<PagedData<TalentPositionPreviewData>> {
        if (!this._getActiveTalentPositionRecommendations) throw new Error("resource has not been properly initialized")
        return this._getActiveTalentPositionRecommendations(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/positionRelations/bookmarked',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getBookmarkedPositionProposals: IResourceMethodObservableStrict<null, {pageSize?: number, pageNum?: number}, null, PagedData<TalentPositionPreviewData>> | undefined
    getBookmarkedPositionProposals(requestParams?: {pageSize?: number, pageNum?: number}): Promise<PagedData<TalentPositionPreviewData>> {
        if (!this._getBookmarkedPositionProposals) throw new Error("resource has not been properly initialized")
        return this._getBookmarkedPositionProposals(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/proposals/archive',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getRejectedPositionProposals: IResourceMethodObservableStrict<null, {pageSize?: number, pageNum?: number}, null, PagedData<TalentPositionDetailsData>> | undefined
    getRejectedPositionProposals(requestParams?: {pageSize?: number, pageNum?: number}): Promise<PagedData<TalentPositionDetailsData>> {
        if (!this._getRejectedPositionProposals) throw new Error("resource has not been properly initialized")
        return this._getRejectedPositionProposals(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/positionRelations/positions/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentPositionRelationByPositionId: IResourceMethodObservableStrict<null, {origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive' | 'Careerguide')}, {id: number}, TalentPositionDetailsData> | undefined
    getTalentPositionRelationByPositionId(id: number, requestParams: {origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive' | 'Careerguide')}): Promise<TalentPositionDetailsData> {
        if (!this._getTalentPositionRelationByPositionId) throw new Error("resource has not been properly initialized")
        return this._getTalentPositionRelationByPositionId(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/positionRelations/clicked',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _markPositionProposalsAsClicked: IResourceMethodObservableStrict<null, {positionIds: number[], origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive' | 'Careerguide')}, null, null> | undefined
    markPositionProposalsAsClicked(requestParams: {positionIds: number[], origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive' | 'Careerguide')}): Promise<null> {
        if (!this._markPositionProposalsAsClicked) throw new Error("resource has not been properly initialized")
        return this._markPositionProposalsAsClicked(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/positionRelations/reject',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _rejectPositionProposal: IResourceMethodObservableStrict<RejectPositionProposalData, {origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive' | 'Careerguide')}, null, JsonWrappedValue<boolean>> | undefined
    rejectPositionProposal(requestBody: RejectPositionProposalData, requestParams: {origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive' | 'Careerguide')}): Promise<boolean> {
        if (!this._rejectPositionProposal) throw new Error("resource has not been properly initialized")
        return this._rejectPositionProposal(requestBody, requestParams, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/talent/positionRelations/bookmark',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeBookmark: IResourceMethodObservableStrict<null, {positionId: number}, null, null> | undefined
    removeBookmark(requestParams: {positionId: number}): Promise<null> {
        if (!this._removeBookmark) throw new Error("resource has not been properly initialized")
        return this._removeBookmark(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talent/positions/{id}/ctaClick',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _trackTalentCtaClickedForPosition: IResourceMethodObservableStrict<null, {origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive' | 'Careerguide')}, {id: number}, null> | undefined
    trackTalentCtaClickedForPosition(id: number, requestParams: {origin: ('Recommendations' | 'Matches' | 'Search' | 'HybridSearch' | 'ElasticSearch' | 'Bookmarks' | 'CompanyProfile' | 'Klarsolar' | 'InstantApplication' | 'Subsequent' | 'Unknown' | 'Archive' | 'Careerguide')}): Promise<null> {
        if (!this._trackTalentCtaClickedForPosition) throw new Error("resource has not been properly initialized")
        return this._trackTalentCtaClickedForPosition(null, requestParams, {id: id}).toPromise()
    }

}