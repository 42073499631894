import { Component} from '@angular/core';
import {DemodeskService} from "../../demo-booking/demodesk.service";

@Component({
  selector: 'app-support',
  standalone: true,
  imports: [],
  templateUrl: './support.component.html',
  styleUrl: './support.component.scss'
})
export class SupportComponent {
  constructor(
      private demodeskService: DemodeskService,
  ) {}

  routeToDemoDesk() {
    this.demodeskService.navToDemodesk('Navbar')
  }
}


