<div class="video-preview"
     [routerLink]="['/company/hr-insights-hub/details/', videoData[index].slug]"
     (mouseover)="playVideo(videoElement)"
     (mouseleave)="stopVideo(videoElement)">
  <div class="overlay" [ngStyle]="{'background-image': 'url(' + previewUrl + ')'}"></div>
  <video #videoElement
         [src]="videoUrl"
         class="video-element"
         [muted]="true"
         preload="metadata">
  </video>
</div>
