<section class="header">
  <h2>Kontakt</h2>
  <h1>Noch offene Fragen?<br>
  Wir haben die Antworten.</h1>
</section>
<section class="support-container">
  <div class="student-card">
    <h3>Studenten🎓</h3>
    <p>Fragen oder Probleme mit deinem Talentagent-Konto? Schreib uns einfach eine Nachricht und wir helfen dir weiter!</p>
    <p>Wenn du Fragen zu einer deiner Bewerbungen hast, wende dich am besten direkt an das jeweilige Unternehmen.
      Dort können deine Anliegen rund um die Bewerbung schneller und genauer beantwortet werden.</p>

    <div class="contact-wrapper">
      <div class="links-with-img">
        <img src="assets/images/support/student-tel.png" alt="tel">
        <a href="tel:+4935179903384">+49 3517 9903384</a>
      </div>
      <div class="links-with-img">
        <img src="assets/images/support/student-mail.png" alt="email">
        <a href="mailto:hilfe@talentagent.de">hilfe&#64;talentagent.de</a>
      </div>
    </div>
    <div class="speech-wrapper">
      <div class="speech">
        <img class="profile-pic" src="assets/images/support/student-profile.png" alt="">
        <div class="speech-bubble">
          <div class="quote">
            Ich helfe dir gerne weiter.
          </div>
          <div class="quoter">
            Sophie,<br>
            Student Communication Managerin
          </div>
        </div>

      </div>
    </div>

  </div>
  <div class="company-card">
    <h3>Unternehmen💼</h3>
    <p>Fragen zu Talentagent, unserem Qualitätsversprechen oder den Bewerberpaketen?
      Kontaktiere uns – wir machen die Talentgewinnung schnell und einfach!</p>
    <div class="contact-wrapper">
      <div class="links-with-img">
        <img src="assets/images/support/company-tel.png" alt="tel">
        <a href="tel:+4935189670255">+49 3518 9670255</a>
      </div>
      <div class="links-with-img">
        <img src="assets/images/support/company-mail.png" alt="email">
        <a href="mailto:anfrage@talentagent.de">anfrage&#64;talentagent.de</a>
      </div>
    </div>
    <div class="speech-wrapper">
      <div class="speech">
        <img class="profile-pic" src="assets/images/support/maxi.png" alt="">
        <div class="speech-bubble">
          <div class="quote">
            Erreiche mit uns die Top-Talente der Gen Z!
          </div>
          <div class="quoter">
            Maxi,<br>
            Sales Managerin
          </div>
      </div>
      </div>
      <div class="cta" (click)="routeToDemoDesk()">Democall Vereinbaren</div>
    </div>

  </div>
</section>

