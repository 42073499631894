import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, CompanyBookingData, CompanySalesProposalData, PagedData, CompanyCompleteInvoiceInformationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanySalesProposalController
 */

@Injectable()
@ResourceParams({})
export class CompanySalesProposalResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/salesProposalsView/{id}/approve',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _approvePublicSalesProposal: IResourceMethodObservableStrict<CompanyCompleteInvoiceInformationData, null, {id: string}, CompanyBookingData> | undefined
    approvePublicSalesProposal(requestBody: CompanyCompleteInvoiceInformationData, id: string): Promise<CompanyBookingData> {
        if (!this._approvePublicSalesProposal) throw new Error("resource has not been properly initialized")
        return this._approvePublicSalesProposal(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/salesProposals/{id}/approve',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _approveSalesProposal: IResourceMethodObservableStrict<CompanyCompleteInvoiceInformationData, null, {id: number}, CompanyBookingData> | undefined
    approveSalesProposal(requestBody: CompanyCompleteInvoiceInformationData, id: number): Promise<CompanyBookingData> {
        if (!this._approveSalesProposal) throw new Error("resource has not been properly initialized")
        return this._approveSalesProposal(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/salesProposalsView/{id}/deny',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _denyPublicSalesProposal: IResourceMethodObservableStrict<null, null, {id: string}, JsonWrappedValue<('Open' | 'Approved' | 'Denied' | 'Withdrawn')>> | undefined
    denyPublicSalesProposal(id: string): Promise<('Open' | 'Approved' | 'Denied' | 'Withdrawn')> {
        if (!this._denyPublicSalesProposal) throw new Error("resource has not been properly initialized")
        return this._denyPublicSalesProposal(null, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/company/salesProposals/{id}/deny',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _denySalesProposal: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<('Open' | 'Approved' | 'Denied' | 'Withdrawn')>> | undefined
    denySalesProposal(id: number): Promise<('Open' | 'Approved' | 'Denied' | 'Withdrawn')> {
        if (!this._denySalesProposal) throw new Error("resource has not been properly initialized")
        return this._denySalesProposal(null, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/salesProposals/open',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSalesProposals: IResourceMethodObservableStrict<null, {pageSize: number, pageNum: number}, null, PagedData<CompanySalesProposalData>> | undefined
    getSalesProposals(requestParams: {pageSize: number, pageNum: number}): Promise<PagedData<CompanySalesProposalData>> {
        if (!this._getSalesProposals) throw new Error("resource has not been properly initialized")
        return this._getSalesProposals(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/salesProposalsView/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSingleDraftOpenSalesProposal: IResourceMethodObservableStrict<null, null, {id: string}, CompanySalesProposalData> | undefined
    getSingleDraftOpenSalesProposal(id: string): Promise<CompanySalesProposalData> {
        if (!this._getSingleDraftOpenSalesProposal) throw new Error("resource has not been properly initialized")
        return this._getSingleDraftOpenSalesProposal(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company/salesProposals/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSingleOpenSalesProposal: IResourceMethodObservableStrict<null, null, {id: number}, CompanySalesProposalData> | undefined
    getSingleOpenSalesProposal(id: number): Promise<CompanySalesProposalData> {
        if (!this._getSingleOpenSalesProposal) throw new Error("resource has not been properly initialized")
        return this._getSingleOpenSalesProposal(null, null, {id: id}).toPromise()
    }

}