import {Component, Input} from '@angular/core';
import {FrontendVideoData, videoData} from "../videos";

@Component({
  selector: 'next-video',
  templateUrl: './next-video.component.html',
  styleUrl: './next-video.component.scss'
})
export class NextVideoComponent {
  @Input() video: FrontendVideoData;

  @Input() index: number;
  protected readonly videoData = videoData;
}
