<div class="container">
  <video-preview
    [previewUrl]="videoData[index].previewUrl"
    [videoUrl]="videoData[index].videoUrl"
    [index]=index>
  </video-preview>
  <div class="description-container">
    <div>
      <h5>{{ videoData[index].title }}</h5>
      <p class="description">{{ videoData[index].description }}</p>
    </div>

    <div class="bottom">
      <div class="info-wrapper">
        <div class="icon-wrapper">
          <img class="icon" src="assets/images/stopwatch.png" alt="Icon">
          <p>{{ videoData[index].duration }}</p>
        </div>
      </div>

      <a class="call-to-action-video" [routerLink]="'/company/hr-insights-hub/details/' + videoData[index].slug">
        Video anschauen
      </a>
    </div>

    </div>

</div>
