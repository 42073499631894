<div class="accordion-item" *ngFor="let item of items; let index = index"
     [attr.id]="trackingPrefix ? trackingPrefix + (index + 1) : null">
  <div class="accordion-title" (click)="toggle(index)">
    <h3>{{ item.title }}</h3>
    <svg xmlns="http://www.w3.org/2000/svg" class="plus" viewBox="0 0 160 160" [class.minus]="index == openedItemIndex">
      <rect class="vertical-line" x="70" width="20" height="160"/>
      <rect class="horizontal-line" y="70" width="160" height="20"/>
    </svg>
  </div>
  <div class="accordion-content" [class.open]="index == openedItemIndex" id="content-{{index}}"
       [ngStyle]="{ 'height': getContentHeight(index)}">
    <p [innerHTML]="item.content"></p>

    <ng-container *ngIf="item.template">
      <ng-container *ngTemplateOutlet="item.template" ></ng-container>
    </ng-container>

    <ng-template *ngIf="item.content">
      <p [innerHTML]="item.content"></p>
    </ng-template>

  </div>
</div>
