import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, CoachingOfferData, CoachingTypeData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.shared.CoachingTypeController
 */

@Injectable()
@ResourceParams({})
export class CoachingTypeResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/coachings',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllCoachingTypes: IResourceMethodObservableStrict<null, null, null, CoachingTypeData[]> | undefined
    getAllCoachingTypes(): Promise<CoachingTypeData[]> {
        if (!this._getAllCoachingTypes) throw new Error("resource has not been properly initialized")
        return this._getAllCoachingTypes(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/coachings/talent',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSelectedCoachingTypes: IResourceMethodObservableStrict<null, null, null, CoachingTypeData[]> | undefined
    getSelectedCoachingTypes(): Promise<CoachingTypeData[]> {
        if (!this._getSelectedCoachingTypes) throw new Error("resource has not been properly initialized")
        return this._getSelectedCoachingTypes(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/coachings/talents/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSelectedCoachingTypesOfTalent: IResourceMethodObservableStrict<null, null, {id: number}, CoachingOfferData[]> | undefined
    getSelectedCoachingTypesOfTalent(id: number): Promise<CoachingOfferData[]> {
        if (!this._getSelectedCoachingTypesOfTalent) throw new Error("resource has not been properly initialized")
        return this._getSelectedCoachingTypesOfTalent(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/coachings/me',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateSelectedCoachings: IResourceMethodObservableStrict<JsonWrappedValue<number[]>, null, null, null> | undefined
    updateSelectedCoachings(requestBody: JsonWrappedValue<number[]>): Promise<null> {
        if (!this._updateSelectedCoachings) throw new Error("resource has not been properly initialized")
        return this._updateSelectedCoachings(requestBody, null, null).toPromise()
    }

}