import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {InvoiceFilterData, CompanyInvoiceData, CompanyInvoiceInformationData, PagedData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.company.CompanyInvoiceController
 */

@Injectable()
@ResourceParams({})
export class CompanyInvoiceResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company_new/inovoiceInformation',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCompanyInvoiceInformation: IResourceMethodObservableStrict<null, null, null, CompanyInvoiceInformationData> | undefined
    getCompanyInvoiceInformation(): Promise<CompanyInvoiceInformationData> {
        if (!this._getCompanyInvoiceInformation) throw new Error("resource has not been properly initialized")
        return this._getCompanyInvoiceInformation(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/company_new/invoice',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFilteredInvoices: IResourceMethodObservableStrict<InvoiceFilterData, null, null, PagedData<CompanyInvoiceData>> | undefined
    getFilteredInvoices(requestBody: InvoiceFilterData): Promise<PagedData<CompanyInvoiceData>> {
        if (!this._getFilteredInvoices) throw new Error("resource has not been properly initialized")
        return this._getFilteredInvoices(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/company_new/invoices/{id}/pdf',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getInvoiceAsPdf: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getInvoiceAsPdf(id: number): Promise<Blob> {
        if (!this._getInvoiceAsPdf) throw new Error("resource has not been properly initialized")
        return this._getInvoiceAsPdf(null, null, {id: id}).toPromise()
    }

}