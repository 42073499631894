<section *ngIf="video" class="header">
  <div>
    <h1 class="top-header">{{ video.topTitle }}<span class="stroke"> - </span></h1>
    <h1>{{ video.title }}</h1>
  </div>
</section>
<section *ngIf="video">

  <div style="padding:56.25% 0 0 0;position:relative;" class="vimeo-wrapper">
      <iframe [src]="videoUrl"
              class="vimeo-player"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              style="position:absolute;top:0;left:0;width:100%;height:100%;">
      </iframe>

  </div>

  <script src="https://player.vimeo.com/api/player.js"></script>

</section>

<section *ngIf="video">

  <div class="details-wrapper">
    <div>
      <h5>Details</h5>
      <div class="bar">
        <div class="colored-bar"></div>
      </div>

      <div class="details">
        <p [innerHTML]="video.longDescription"></p>

        <div *ngFor="let checkpoint of video.checkpoints">
          <checked-point
            [iconSrc]="'../../../../assets/images/check.svg'"
            [boldText]="checkpoint.boldText"
            [description]="checkpoint.description">
          </checked-point>
        </div>
      </div>
    </div>


    <div class="cta-container">
      <h6>Erreichst du die Top-Talente der Gen Z?</h6>
      <p class="details">
        Erhalte 3x mehr passende Juniorbewerbungen mit Talentagent, der führenden Job-Plattform für Uni-Absolventen.
      </p>
      <div class="quote">
        <img class="maxi" src="../../../../../assets/images/maxi.png" loading="lazy" alt="">
        <div class="maxis-speech-bubble">
          <p class="statement">Lerne in 20 Minuten Talentagent kennen</p>
          <p class="quoter">
            Maxi,<br>
            Sales Managerin</p>
        </div>
      </div>
      <div></div>

      <a class="cta" (click)="routeToDemoDesk('Navbar')">Democall Vereinbaren</a>
    </div>
  </div>

</section>

<div class="section-spacer"></div>

<section *ngIf="video">
  <h4>Weitere Videos anschauen</h4>
  <div class="more-videos">
    <next-video *ngFor="let suggestedIndex of video.suggestedVideos"
                [video]="videos[suggestedIndex]" [index]="suggestedIndex">
    </next-video>
  </div>
</section>
