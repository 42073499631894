<section class="header">
    <div class="hero-wrapper">
        <div class="hero">
            <div>
                <h2>Report</h2>
                <h1>Gen Z Insights:<br> Wünsche und Ängste zum Berufseinstieg</h1>
            </div>
            <button class="cta get-response-trigger-download-report">Report herunterladen</button>
        </div>
        <img class="hero-image" src="assets/images/hr-report-thumbnail.png" alt="">
    </div>
</section>
<section>
    <h1 class="most-important">Wichtigsten Ergebnisse im Überblick</h1>
    <div class="stats-container">
        <div class="stat">
            <p class="metric">98 %</p>
            <p class="metric-description">finden das Arbeitsumfeld und die Kultur am wichtigsten</p>
        </div>
        <div class="stat">
            <p class="metric">65 %</p>
            <p class="metric-description">würden am liebsten Hybrid vor Ort und im Homeoffice arbeiten</p>
        </div>
        <div class="stat">
            <p class="metric">24 %</p>
            <p class="metric-description">haben Angst vor Vertragsverhandlungen</p>
        </div>
    </div>
</section>
<section class="large-cta">
    <div class="large-cta-wrapper">
        <div class="report-images">
            <img class="move-img" src="assets/images/report/bereitUmzuziehen.png" alt="">
            <img class="searching-img" src="assets/images/report/GenZ-suchtKeineGroßen.png" alt="">
            <img class="uncertainty-img" src="assets/images/report/wenigerUnsicherheiten.png" alt="">
        </div>
        <div class="report-details">
            <h1 class="download-report-heading">Online-Studie: Karriereinteressen von Studierenden</h1>
            <p>
                Du erhältst im Studien-Report konkrete Einblicke in die Erwartungen, Wünsche, Ängste und Unsicherheiten der Generation Z beim Jobeinstieg.
                Nutze unsere datenbasierten Handlungsempfehlungen und positioniere dich als Wunscharbeitgeber.
            </p>
            <div>
                <checked-point [description]="'Erfahre die wichtigsten Faktoren für die Wahl des ersten Arbeitgebers nach dem Studium. Schaffe ein Arbeitsumfeld, in dem sich die Talente wohlfühlen und ihr Potenzial entfalten können.'"></checked-point>
                <checked-point [description]="'Nutze die Erkenntnisse aus unserer Online-Befragung, erstelle ein unwiderstehliches Jobangebot, vermeide unnötige Barrieren und erreiche die Talente auf den richtigen Recruiting-Kanälen.'"></checked-point>
                <checked-point [description]="'Nimm den jungen Absolventen die Unsicherheit beim Berufseinstieg, steigere ihre Zufriedenheit und binde sie langfristig an dein Unternehmen.'"></checked-point>
            </div>
            <button class="cta get-response-trigger-download-report">Report herunterladen</button>
        </div>
    </div>
</section>
<section class="recruiting-lab">
    <h4>Recruiting Video Lab</h4>
    <div class="more-videos">
        <next-video [video]="videoData[0]" [index]="0"></next-video>
        <next-video [video]="videoData[1]" [index]="1"></next-video>
        <next-video [video]="videoData[2]" [index]="2"></next-video>
    </div>
</section>
