import {Injectable} from '@angular/core';
import {Meta, MetaDefinition} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(
    private meta: Meta,
  ) {
  }

  setMetaTag(tag: MetaDefinition) {
    this.removeMetaTag(tag.name)

    this.meta.getTag(tag.name) == null
      ? this.meta.addTag(tag, true)
      : this.meta.updateTag(tag);
  }

  removeMetaTag(tag: string) {
    this.meta.removeTag(`name =${tag}`)
  }

  resetRobotsTag() {
    this.meta.updateTag({name: 'robots', content: 'index'});
  }

  setRobotsTag(index: boolean) {
    const content = index ? 'index' : 'noindex';
    this.setMetaTag({ name: 'robots', content });
  }

  setThemeColor(color: string) {
    const light = document.createElement('meta');
    light.setAttribute('name', 'theme-color');
    light.setAttribute('content', color);
    light.setAttribute('media', '(prefers-color-scheme: light)');

    const dark = document.createElement('meta');
    dark.setAttribute('name', 'theme-color');
    dark.setAttribute('content', color);
    dark.setAttribute('media', '(prefers-color-scheme: dark)');

    document.head.appendChild(light);
    document.head.appendChild(dark);
  }
}
