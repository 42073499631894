<div class="width-100 flex center column">

  <section class="flex column center header-section width-100 overflow-clip header-gradient" [ngClass]="{'margin-bottom-lg': mediaService.isMobile()}">
    <div
      class="flex flex-space-between-center content-width"
      [ngClass]="{'row': !mediaService.getMaxWidthQuery(800).matches, 'column': mediaService.getMaxWidthQuery(800).matches,
      'gap-lg':mediaService.getMaxWidthQuery(800).matches, 'gap-sm': !mediaService.getMaxWidthQuery(800).matches,
      'width-80': !mediaService.getMaxWidthQuery(1200).matches, 'width-100': mediaService.getMaxWidthQuery(1200).matches}">
      <div
        [ngClass]="{'center-start': mediaService.getScreenWidth() > 1000,
            'padding-vertical-xl': mediaService.getScreenWidth() > 1000}"
        class="flex column width-100 z-index-1">
        <h1 class="page-header">
          Junior-Recruiting <br> <span class="highlight no-wrap-text">leicht gemacht</span>
        </h1>
        <p class="regular-text margin-bottom-lg" [ngClass]="{'margin-vertical': mediaService.getScreenWidth() > 1000, 'center-text': mediaService.getMaxWidthQuery(800).matches}">
          Einfache und schnelle Talentgewinnung auf <br> der Nr. 1 Jobplattform für Uni-Absolventen
        </p>
        <button class="accent-button" *ngIf="!mediaService.getMaxWidthQuery(800).matches" (click)="navToDemodesk('Header')">
          Democall vereinbaren
        </button>
      </div>

      <div class="hero-wrapper"
           [ngClass]="{'center-stretch': !mediaService.getMaxWidthQuery(1000).matches, 'end': mediaService.getMaxWidthQuery(1000).matches, 'width-100': mediaService.getMaxWidthQuery(1000).matches}">
        <div class="header-usps-wrapper">
          <div class="header-cta perspective-blend-in delay-0-2s">
            Hochschulabschluss
            <i class="mdi mdi-check"></i>
          </div>
          <div class="header-cta perspective-blend-in delay-0-3s">
            Deutsch C1
            <i class="mdi mdi-check"></i>
          </div>
          <div class="header-cta perspective-blend-in delay-0-4s">
            EU-Arbeitserlaubnis
            <i class="mdi mdi-check"></i>
          </div>
        </div>

        <img class="header-image-card image-card perspective-blend-in opacity-0" src="assets/images/stock-two-people-laptop.jpg" alt="">

        <div class="header-graph-container-wrapper perspective-blend-in delay-0-2s opacity-0">
          <div class="header-graph-container">
            <div class="graph-pillar" style="height:7%"></div>
            <div class="graph-pillar" style="height:10%"></div>
            <div class="graph-pillar" style="height:15%"></div>
            <div class="graph-pillar" style="height:20%"></div>
            <div class="graph-pillar" style="height:30%"></div>
            <div class="graph-pillar" style="height:80%"></div>
            <div class="graph-pillar" style="height:90%"></div>
            <div class="graph-pillar" style="height:100%"></div>

            <div class="graph-text">
              <b>3x mehr</b> passende Bewerbungen
            </div>
          </div>
        </div>

        <svg class="hero-arrow perspective-blend-in delay-0-4s opacity-0" xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" fill="none">
          <circle cx="28.0752" cy="28.0752" r="28.0752" fill="#00C8BE"/>
          <path d="M41.0807 17.3832C40.9792 15.9656 39.7457 14.8969 38.3281 14.9983L20.1822 16.2973C18.7646 16.3988 17.6959 17.6323 17.7974 19.05C17.8989 20.4676 19.1324 21.5364 20.55 21.4349L32.5088 20.5731L16.357 39.2158C15.4251 40.2913 15.5412 41.9131 16.6168 42.845C17.6923 43.7768 19.3141 43.6607 20.2459 42.5852L36.3925 23.9485L37.2535 35.896C37.355 37.3136 38.5885 38.3824 40.0062 38.2809C41.4238 38.1794 42.4925 36.9459 42.3911 35.5282L41.0921 17.3824L41.0807 17.3832Z" fill="white"/>
        </svg>
      </div>

      <button class="accent-button" *ngIf="mediaService.getMaxWidthQuery(800).matches" (click)="navToDemodesk('Header')">
        Democall vereinbaren
      </button>
    </div>

  </section>

  <section class="flex center column gap-lg padding cap-width-sm " [ngClass]="{'margin-bottom-lg': mediaService.isMobile()}">

    <h1 class="medium-section-header center-text">
      Wir sortieren vor, <br>
      du sparst wertvolle Zeit.
    </h1>

    <div class="flex column gap center">
      <div class="flex center-stretch gap" [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}">
        <div class="b2b-usp-card background-light-teal">
          <div class="flex row gap-lg">
            <div class="flex">
              <i class="usp-icon fa-solid fa-address-card color-teal"></i>
              <img class="usp-icon-check" src="assets/images/check-round-vector.svg" alt="">
            </div>
            <h2 class="bold-text">
              Weniger Aufwand, mehr Relevanz
            </h2>
          </div>
          <p>Mit Talentagent erhältst du nur geprüfte Bewerber mit Hochschulabschluss, Deutsch C1-Niveau und EU-Arbeitserlaubnis.</p>
        </div>

        <div class="b2b-usp-card background-pastel-accent">
          <div class="flex row gap-lg">
            <i class="usp-icon fa-solid fa-layer-group color-pastel-accent"></i>
            <h2 class="bold-text">
              Garantierte Anzahl an Juniorbewerbungen
            </h2>
          </div>
          <p>Deine Anzeigen bleiben auf Talentagent online, bis dein gebuchtes Bewerberpaket ausgeschöpft ist.</p>
        </div>
      </div>

      <div class="flex center-stretch gap" [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}">
        <div class="b2b-usp-card background-pastel-pink">
          <div class="flex row gap-lg">
            <i class="usp-icon fa-solid fa-sliders color-pastel-pink"></i>
            <h2 class="bold-text">
              Jobanzeigen ohne feste Laufzeit
            </h2>
          </div>
          <p>Veröffentliche, optimiere und pausiere beliebig viele Stellenanzeigen ohne Zeitdruck durch fehlende Laufzeiten.</p>
        </div>

        <div class="b2b-usp-card background-pastel-violet">
          <div class="flex row gap-lg">
            <i class="usp-icon fa-solid fa-graduation-cap color-pastel-violet"></i>
            <h2 class="bold-text">
              Zielgerichtete Stellenausspielung
            </h2>
          </div>
          <p>Dein Jobangebot erreicht nur passende Talente. Zusätzlich wird deine Sichtbarkeit bei den Uni-Absolventen per E-Mail und SMS erhöht.</p>
        </div>
      </div>
    </div>

    <button class="accent-button" (click)="navToDemodesk('Benefits')">
      Democall vereinbaren
    </button>

  </section>

  <section
          [ngClass]="{'padding-xl': !mediaService.isMobile(), 'padding': mediaService.isMobile(), 'margin-bottom-lg': mediaService.isMobile()}"
          class="flex center column gap-lg cap-width-sm" >

    <h1 class="medium-section-header" [ngClass]="{'center-text':  !mediaService.isMobile()}">
      Erreiche mit Talentagent frühzeitig <br *ngIf="!mediaService.isMobile()">
      die Spitzenkräfte von morgen
    </h1>

    <p class="regular-text" [ngClass]="{'center-text': !mediaService.isMobile()}">
      Mit unserer Plattform <b>Hochschulinitiative Deutschland</b> unterstützen wir Studierende mit Weiterbildungen und beruflichen Angeboten.
      Wir machen zukünftige Uni-Absolventen auf die Jobsuche auf Talentagent aufmerksam und begeistern die passenden Talente frühzeitig für deine Stellenangebote.
      Damit legen wir den Grundstein für eine erfolgreiche Talentgewinnung.
    </p>

    <div class="flex center gap width-100"
         [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}">

      <div class="flex row gap"
           [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}">
        <div class="stat-usp background-pastel-violet color-pastel-violet">
          <h2>> 8 Jahre</h2>
          <div>Erfahrung im Talentrecruitment</div>
        </div>
        <div class="stat-usp background-pastel-accent color-accent">
          <h2>> 60 %</h2>
          <div>Markenbekanntheit bei Studierenden</div>
        </div>
      </div>

      <div class="flex row gap"
           [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}">
        <div class="stat-usp background-pastel-pink color-pastel-pink">
          <h2>~ 400.000</h2>
          <div>Monatliche Seitenbesucher</div>
        </div>
        <div class="stat-usp background-light-teal color-teal">
          <h2>> 46.000</h2>
          <div>Juniorbewerbungen übermittelt</div>
        </div>
      </div>

    </div>

    <div class="stats-image-wrapper">
      <img class="stats-image-degree" src="assets/images/degree-stock.png" alt="">
      <img class="stats-image-students" src="assets/images/four-students.png" alt="">
      <div class="inverted-border-radius"></div>
      <div class="stats-hid-logo">
        <img src="assets/images/HID_logo.png" alt="">
        <img class="stats-check" src="assets/images/Check_vector.svg" alt="">
      </div>
    </div>

    <button class="accent-button" (click)="navToDemodesk('HIDCrossover')">
      Democall vereinbaren
    </button>

  </section>

  <section
          [ngClass]="{'padding-xl': !mediaService.isMobile(), 'padding-lg': mediaService.isMobile(), 'margin-bottom-lg': mediaService.isMobile()}"
          class="flex column center gap-xl width-100 background-light-teal">

    <h2 class="medium-section-header center-text">
      Diese Erfolge feiern <br>
      unsere Kunden
    </h2>

      <div class="cap-width width-100">
          <div
                  [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}"
                  class="flex column gap start">
              <div
                      [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"
                      class="flex column start"
              >
                  <div class="display-video-about">

                      <div style="padding:56.25% 0 0 0;position:relative;">
                          <iframe src="https://player.vimeo.com/video/937795637?h=d40eceacd0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                  frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                  style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                  title="Userlike Testimonial"></iframe>
                      </div>
                      <script src="https://player.vimeo.com/api/player.js"></script>

                      <!--
                      <iframe
                        *ngIf="mediaService.getScreenWidth() > 768"
                        onload="this.width= (screen.width / 3.33);"
                        height="300"
                        src="https://syntacton.sharepoint.com/sites/Marketing/_layouts/15/embed.aspx?UniqueId=2a887986-76b9-44b0-8b4b-b7a14340d32f&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
                        frameborder="0" scrolling="no" allowfullscreen title="240313_testimonial-kristina_V2.mp4"></iframe>

                      <iframe
                        *ngIf="mediaService.getScreenWidth() <= 768"
                        onload="this.width= (screen.width * 0.95);"
                        height="300"
                        src="https://syntacton.sharepoint.com/sites/Marketing/_layouts/15/embed.aspx?UniqueId=2a887986-76b9-44b0-8b4b-b7a14340d32f&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
                        frameborder="0" scrolling="no" allowfullscreen title="240313_testimonial-kristina_V2.mp4"></iframe>
                        -->
                  </div>
              </div>

              <div [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile(),
              'gap': !mediaService.isMobile(), 'gap-lg': mediaService.isMobile(), 'start': !mediaService.isMobile(), 'start-center': mediaService.isMobile()}"
                   class="flex column">

                <div class="flex column start gap" *ngIf="mediaService.isMobile()">
                  <p class="quote-text">{{caseStudyData.quoteText}}</p>

                  <div class="flex">
                    <p class="quote-author">{{caseStudyData.quoteAuthor}}</p>,&nbsp;
                    <p class="quote-author-position">{{caseStudyData.quoteAuthorTitle}}</p>
                  </div>
                </div>

                  <div
                          [ngClass]="{'column': mediaService.getScreenWidth() <= 768, 'row': mediaService.getScreenWidth() > 768}"
                          class="flex center width-100">
                      <div
                              [ngClass]="{
        'row': mediaService.getScreenWidth() <= 768,
        'start-center': mediaService.getScreenWidth() <= 768,
        'column': mediaService.getScreenWidth() > 768,
        'center-start': mediaService.getScreenWidth() > 768
        }"
                              class="usp-container first-usp flex padding">
                          <div class="usp-icon-wrapper flex center">
                              <i class="usp-icon mdi mdi-account-group"></i>
                          </div>
                          <div class="flex column start">
                              <h2 class="bold-text color-regular">3x</h2>
                              <p class="color-regular bold-text">Mehr passende Juniorbewerber</p>
                          </div>
                      </div>

                      <div
                              [ngClass]="{
        'row': mediaService.getScreenWidth() <= 768,
        'start-center': mediaService.getScreenWidth() <= 768,
        'column': mediaService.getScreenWidth() > 768,
        'center-start': mediaService.getScreenWidth() > 768
        }"
                              class="usp-container second-usp flex padding">
                          <div class="usp-icon-wrapper flex center delay-0-2s">
                              <i class="usp-icon mdi mdi-email-check"></i>
                          </div>
                          <div class="flex column start">
                              <h2 class="bold-text color-regular">20%</h2>
                              <p class="color-regular bold-text">Bewerbungsrate auf die Anzeige</p>
                          </div>
                      </div>

                      <div
                              [ngClass]="{
        'row': mediaService.getScreenWidth() <= 768,
        'start-center': mediaService.getScreenWidth() <= 768,
        'column': mediaService.getScreenWidth() > 768,
        'center-start': mediaService.getScreenWidth() > 768
        }"
                              class="usp-container third-usp flex padding">
                          <div class="usp-icon-wrapper flex center delay-0-4s">
                              <i class="usp-icon mdi mdi-phone"></i>
                          </div>
                          <div class="flex column start">
                              <h2 class="bold-text color-regular">60%</h2>
                              <p class="color-regular bold-text">Kommen weiter ins Gespräch</p>
                          </div>
                      </div>

                  </div>

                  <div class="flex column start gap" *ngIf="!mediaService.isMobile()">
                    <p class="quote-text">{{caseStudyData.quoteText}}</p>

                    <div class="flex">
                      <p class="quote-author">{{caseStudyData.quoteAuthor}}</p>,&nbsp;
                      <p class="quote-author-position">{{caseStudyData.quoteAuthorTitle}}</p>
                    </div>
                  </div>

                <button class="accent-button" (click)="navToDemodesk('Testimonial')">
                  Democall vereinbaren
                </button>
              </div>

          </div>

      </div>

  </section>


  <!--<section [ngClass]="{-->
  <!-- 'padding-horizontal-xl': !mediaService.isMobile(),-->
  <!-- 'padding-horizontal': mediaService.isMobile()}"-->
  <!--         class="column flex center width-100 cap-width" *ngIf="webinarData?.length">-->
  <!--    <div class="width-100 flex row flex-space-between-center">-->
  <!--        <h2 class="big-section-header padding-vertical-lg start-text">Unsere Webinare</h2>-->
  <!--        <button *ngIf="!mediaService.isMobile()" class="accent-button">Komm zu unserem Webinar</button>-->
  <!--    </div>-->

  <!--    <div class="width-100 cap-width flex gap-lg"-->
  <!--         [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile()}"-->
  <!--         *ngIf="webinarData?.length">-->

  <!--        <div class="guide" [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"-->
  <!--             *ngIf="webinarData?.length != 1">-->
  <!--            <app-webinar-card [webinarData]="webinarData[0]" [size]="'Medium'"></app-webinar-card>-->
  <!--        </div>-->

  <!--        <div class="flex column gap-lg"-->
  <!--             [ngClass]="{'width-50': !mediaService.isMobile(), 'width-100': mediaService.isMobile()}"-->
  <!--             *ngIf="webinarData?.length != 1">-->
  <!--            <div class="height-50 guide delay-0-5s">-->
  <!--                <app-webinar-card [webinarData]="webinarData[1]" [size]="'Small'"></app-webinar-card>-->
  <!--            </div>-->
  <!--            <div class="height-50 guide delay-1s" *ngIf="webinarData?.length == 3">-->
  <!--                <app-webinar-card [webinarData]="webinarData[2]" [size]="'Small'"></app-webinar-card>-->
  <!--            </div>-->
  <!--        </div>-->

  <!--        <div class="guide width-100 flex row center" *ngIf="webinarData?.length == 1">-->
  <!--            <app-webinar-card [webinarData]="webinarData[0]" [size]="'Large'"></app-webinar-card>-->
  <!--        </div>-->
  <!--    </div>-->

  <!--    <div class="padding-vertical-lg width-100 flex row center">-->
  <!--        <button class="accent-button">Komm zu unserem Webinar</button>-->
  <!--    </div>-->


  <!--</section>-->


  <section
          [ngClass]="{'padding-vertical': mediaService.isMobile(), 'padding-vertical-xl': !mediaService.isMobile()}"
          class="flex column center width-100" #pricingSection id="pricingSection">
      <h2 class="medium-section-header padding-vertical-lg center-text">
        Wie viele Juniorstellen <br>
        musst du besetzen?
      </h2>

    <div class="flex row center-start width-100">
      <div class="flex column gap-sm pricing-options-text-container" *ngIf="mediaService.getScreenWidth() > 1200">
        <ul class="pricing-options-list flex column gap-sm">
          <li class="application-highlight">Garantierte Anzahl an Bewerbungen</li>
          <li>Bewerbungen mit Qualitätsgarantie</li>
          <div class="light-divider"></div>
          <li>Anzeigen ohne Laufzeit</li>
          <div class="light-divider"></div>
          <li>Zielgerichtete Stellenausspielung</li>
          <div class="light-divider"></div>
          <li class="flex row width-100 end gap-sm default-weight-text">
            <i class="mdi mdi-information-slab-circle"></i>
            inkl. Premiumunternehmensprofil
            <div class="info-tooltip">
              {{ primeCompanyTooltipText }}
            </div>
          </li>
          <div class="light-divider"></div>
          <li class="flex row width-100 end gap-sm default-weight-text">
            <i class="mdi mdi-information-slab-circle"></i>
            inkl. Premiumstellenanzeige
            <div class="info-tooltip">
              {{ topJobTooltipText }}
            </div>
          </li>
        </ul>
      </div>

      <div class="pricing-options-wrapper" [ngClass]="{'grid': mediaService.getScreenWidth() > 1200}">

        <div class="pricing-option-container-highlighted" *ngIf="mediaService.getScreenWidth() <= 1200">
          <div class="flex column height-100">
            <div class="pricing-option-header flex column gap flex-space-between-center">
              <div class="discount-container">
                12.5% Rabatt
              </div>
              <div class="flex column center">
                <h3>Vorteilspaket</h3>
                <h2>€3.500</h2>
                <em>Empfohlen für 2-3 Stellen</em>
              </div>
            </div>

            <div class="pricing-option-content">
              <ul class="pricing-options-list">
                <li [ngClass]="{'application-highlight': mediaService.getScreenWidth() > 1200}">
                  <i class="mdi" [ngClass]="{'mdi-file-move': mediaService.getScreenWidth() > 1200, 'mdi-check-circle': mediaService.getScreenWidth() <= 1200}"></i>
                  <div *ngIf="mediaService.getScreenWidth() > 1200">40 Bewerbungen</div>
                  <div *ngIf="mediaService.getScreenWidth() <= 1200">Garantiert <b>40 Bewerbungen</b></div>
                </li>
                <li>
                  <i class="mdi mdi-check-circle"></i>
                  <span>Bewerbungen mit Qualitätsgarantie</span>
                </li>
                <div class="light-divider"></div>
                <li>
                  <i class="mdi mdi-check-circle"></i>
                  <span>Anzeigen ohne Laufzeit</span>
                </li>
                <div class="light-divider"></div>
                <li>
                  <i class="mdi mdi-check-circle"></i>
                  <span>Zielgerichtete Stellenausspielung</span>
                </li>
                <div class="light-divider"></div>
                <li>
                  <i class="mdi mdi-check-circle"></i>
                  <div class="flex row start-center gap-sm default-weight-text width-100">
                    <span>inkl. Premiumunternehmensprofil</span>
                    <i class="mdi mdi-information-slab-circle"></i>
                    <div class="info-tooltip">
                      {{ primeCompanyTooltipText }}
                    </div>
                  </div>
                </li>
                <div class="light-divider"></div>
                <li>
                  <i class="mdi mdi-close-circle"></i>
                  <div class="flex row start-center gap-sm default-weight-text width-100">
                    <span>inkl. Top Job Platzierung</span>
                    <i class="mdi mdi-information-slab-circle"></i>
                    <div class="info-tooltip">
                      {{ topJobTooltipText }}
                    </div>
                  </div>
                </li>
              </ul>

              <button (click)="navToDemodesk('SmallPackage')" class="accent-button margin-bottom">
                Demo buchen
              </button>
            </div>
          </div>
        </div>

        <div class="grid-item-3 pricing-option-container">
          <div class="flex column height-100">
            <div class="pricing-option-header flex column end">
              <h3>Starterpaket</h3>
              <h2>€1.500</h2>
              <em>Empfohlen für 1 Stelle</em>
            </div>

            <div class="pricing-option-content">
              <ul class="pricing-options-list">
                <li [ngClass]="{'application-highlight': mediaService.getScreenWidth() > 1200}">
                  <i class="mdi" [ngClass]="{'mdi-file-move': mediaService.getScreenWidth() > 1200, 'mdi-check-circle': mediaService.getScreenWidth() <= 1200}"></i>
                  <div *ngIf="mediaService.getScreenWidth() > 1200">15 Bewerbungen</div>
                  <div *ngIf="mediaService.getScreenWidth() <= 1200">Garantiert <b>15 Bewerbungen</b></div>
                </li>
                <li>
                  <i class="mdi mdi-check-circle"></i>
                  <span>Bewerbungen mit Qualitätsgarantie</span>
                </li>
                <div class="light-divider"></div>
                <li>
                  <i class="mdi mdi-check-circle"></i>
                  <span>Anzeigen ohne Laufzeit</span>
                </li>
                <div class="light-divider"></div>
                <li>
                  <i class="mdi mdi-check-circle"></i>
                  <span>Zielgerichtete Stellenausspielung</span>
                </li>
                <div class="light-divider"></div>
                <li>
                  <i class="mdi mdi-close-circle"></i>
                  <div class="flex row start-center gap-sm default-weight-text width-100">
                    <span>inkl. Premiumunternehmensprofil</span>
                    <i class="mdi mdi-information-slab-circle"></i>
                    <div class="info-tooltip">
                      {{ primeCompanyTooltipText }}
                    </div>
                  </div>
                </li>
                <div class="light-divider"></div>
                <li>
                  <i class="mdi mdi-close-circle"></i>
                  <div class="flex row start-center gap-sm default-weight-text width-100">
                    <span>inkl. Top Job Platzierung</span>
                    <i class="mdi mdi-information-slab-circle"></i>
                    <div class="info-tooltip">
                      {{ topJobTooltipText }}
                    </div>
                  </div>
                </li>
              </ul>

              <button (click)="navToDemodesk('SmallPackage')" class="light-stroked-button margin-bottom">
                Demo buchen
              </button>
            </div>
          </div>
        </div>

        <div class="grid-item-3 pricing-option-container-highlighted" *ngIf="mediaService.getScreenWidth() > 1200">
          <div class="flex column height-100">
            <div class="pricing-option-header flex column gap flex-space-between-center">
              <div class="discount-container">
                12.5% Rabatt
              </div>
              <div class="flex column center">
                <h3>Vorteilspaket</h3>
                <h2>€3.500</h2>
                <em>Empfohlen für 2-3 Stellen</em>
              </div>
            </div>

            <div class="pricing-option-content">
              <ul class="pricing-options-list">
                <li class="application-highlight">
                  <i class="mdi mdi-file-move"></i>
                  40 Bewerbungen
                </li>
                <li><i class="mdi mdi-check-circle"></i></li>
                <div class="light-divider"></div>
                <li><i class="mdi mdi-check-circle"></i></li>
                <div class="light-divider"></div>
                <li><i class="mdi mdi-check-circle"></i></li>
                <div class="light-divider"></div>
                <li><i class="mdi mdi-check-circle"></i></li>
                <div class="light-divider"></div>
                <li><i class="mdi mdi-close-circle"></i></li>
              </ul>

              <button (click)="navToDemodesk('SmallPackage')" class="accent-button margin-bottom">
                Demo buchen
              </button>
            </div>
          </div>
        </div>

        <div class="grid-item-3 pricing-option-container">
          <div class="flex column height-100">
            <div class="pricing-option-header flex column gap flex-space-between-center">
              <div class="discount-container">
                25% Rabatt
              </div>
              <div class="flex column center">
                <h3>Premiumpaket</h3>
                <h2>€7.500</h2>
                <em>Empfohlen für bis zu 6 Stellen</em>
              </div>
            </div>

            <div class="pricing-option-content">
              <ul class="pricing-options-list">
                <li [ngClass]="{'application-highlight': mediaService.getScreenWidth() > 1200}">
                  <i class="mdi" [ngClass]="{'mdi-file-move': mediaService.getScreenWidth() > 1200, 'mdi-check-circle': mediaService.getScreenWidth() <= 1200}"></i>
                  <div *ngIf="mediaService.getScreenWidth() > 1200">100 Bewerbungen</div>
                  <div *ngIf="mediaService.getScreenWidth() <= 1200">Garantiert <b>100 Bewerbungen</b></div>
                </li>
                <li>
                  <i class="mdi mdi-check-circle"></i>
                  <span>Bewerbungen mit Qualitätsgarantie</span>
                </li>
                <div class="light-divider"></div>
                <li>
                  <i class="mdi mdi-check-circle"></i>
                  <span>Anzeigen ohne Laufzeit</span>
                </li>
                <div class="light-divider"></div>
                <li>
                  <i class="mdi mdi-check-circle"></i>
                  <span>Zielgerichtete Stellenausspielung</span>
                </li>
                <div class="light-divider"></div>
                <li>
                  <i class="mdi mdi-check-circle"></i>
                  <div class="flex row start-center gap-sm default-weight-text width-100">
                    <span>inkl. Premiumunternehmensprofil</span>
                    <i class="mdi mdi-information-slab-circle"></i>
                    <div class="info-tooltip">
                      {{ primeCompanyTooltipText }}
                    </div>
                  </div>
                </li>
                <div class="light-divider"></div>
                <li>
                  <i class="mdi mdi-check-circle"></i>
                  <div class="flex row start-center gap-sm default-weight-text width-100">
                    <span>inkl. Top Job Platzierung</span>
                    <i class="mdi mdi-information-slab-circle"></i>
                    <div class="info-tooltip">
                      {{ topJobTooltipText }}
                    </div>
                  </div>
                </li>
              </ul>

              <button (click)="navToDemodesk('SmallPackage')" class="light-stroked-button margin-bottom">
                Demo buchen
              </button>
            </div>
          </div>
        </div>

        <div class="grid-item-3 pricing-option-container">
          <div class="flex column height-100">
            <div class="pricing-option-header flex column end">
              <h3 class="center-text">Individuelles <br *ngIf="mediaService.getScreenWidth() > 1200"> Paket</h3>
            </div>

            <div class="pricing-option-content">
              <div class="flex column center height-100 gap padding-lg">
                <i class="mdi mdi-cog"></i>
                <p class="center-text slim-text">
                  Du brauchst mehr Bewerbungen oder hast individuelle Anforderungen?
                </p>

                <p class="center-text slim-text">
                  Dann sprich mit unserem Team und lass uns gemeinsam die beste Lösung finden.
                </p>
              </div>

              <button (click)="navToDemodesk('SmallPackage')" class="light-stroked-button margin-bottom">
                Demo buchen
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>


  </section>

  <section class=" background-light-teal flex center width-100" [ngClass]="{'margin-bottom-lg': mediaService.isMobile()}">
    <div class="flex row flex-space-between-center width-100 content-width"
         [ngClass]="{'padding-xl': !mediaService.isMobile(), 'padding': mediaService.isMobile(), 'gap-xl': !mediaService.isTablet(), 'gap': mediaService.isTablet()}">
      <div class="flex column gap-lg">
        <h2 class="medium-section-header" [ngClass]="{'margin-bottom-lg': mediaService.isMobile(), 'center-text': mediaService.isMobile()}">
          Der einfachste Weg <br>
          zu den Top-Talenten der Gen Z
        </h2>

        <div class="flex column center" *ngIf="mediaService.isMobile()">
          <img class="width-100" src="assets/images/Weg_Creative.png" alt="">
        </div>

        <div class="flex column gap-lg">
          <div class="flex gap-lg start-center">
            <div class="step-selected">
              1
            </div>
            <div class="flex column flex-space-between height-100">
              <h3 class="bold-text">Demo buchen</h3>
              <div>Lerne in 20 Minuten Talentagent kennen</div>
            </div>
          </div>

          <div class="flex gap-lg start-center">
            <div class="step">
              2
            </div>
            <div class="flex column flex-space-between height-100">
              <h3 class="bold-text">Stelle schalten</h3>
              <div>Erreiche geprüfte Junior-Talente</div>
            </div>
          </div>

          <div class="flex gap-lg start-center">
            <div class="step">
              3
            </div>
            <div class="flex column flex-space-between height-100">
              <h3 class="bold-text">Bewerber erhalten</h3>
              <div>Spare Zeit und Ressourcen</div>
            </div>
          </div>
        </div>

        <p class="regular-text">
          Wir freuen uns darauf, dir den direkten Weg zu den Spitzenkräften von morgen zu zeigen.
        </p>

        <div class="flex gap start-center" [ngClass]="{'row': !mediaService.isMobile(), 'column': mediaService.isMobile(), 'margin-bottom-lg': mediaService.isMobile()}">
          <button class="accent-button" (click)="navToDemodesk('Process')">
            Democall vereinbaren
          </button>

          <button class="accent-stroked-button" (click)="routeToCompanyOnboarding()">
            Unternehmensaccount anlegen
          </button>
        </div>
      </div>

      <div class="flex column center" *ngIf="!mediaService.getMaxWidthQuery(800).matches">
        <img class="width-100" src="assets/images/Weg_Creative.png" alt="">
      </div>
    </div>
  </section>

  <section [ngClass]="{'padding-xl': !mediaService.isMobile(), 'padding': mediaService.isMobile()}"
           class="flex column gap-lg width-100 content-width">
    <h2 class="medium-section-header">
      Häufige Fragen
    </h2>

    <div class="width-100">
      <app-accordion
        [items]="faqItems"
        [trackingPrefix]="mediaService.isMobile() ? 'faq_ta-b2b_mobile_' : 'faq_ta-b2b_desktop_'">
      </app-accordion>

    </div>

    <ng-template #faqItem>
      <app-competition-comparison></app-competition-comparison>
    </ng-template>
  </section>

  <section [ngClass]="{'padding-xl': !mediaService.isMobile(), 'padding': mediaService.isMobile()}"
           class="flex row center gap-lg width-100">

    <div class="pxtra-container">
      <h3 class="default-weight-text">Unser Partner für flexible Verwaltung von Mitarbeiter-Benefits</h3>
      <a class="pxtra-logo" href="https://pxtra.de/" target="_blank">
        <img src="assets/images/partner-logos/pxtra-logo.png" alt="">
      </a>
    </div>

  </section>
</div>
