import {Component, OnInit} from '@angular/core';
import {DemodeskService} from "../demo-booking/demodesk.service";
import {environment} from "../../../environments/environment";
import {AnalyticsService} from "../../utility/services/gdprconsent/analytics.service";
import {UtmService} from "../../utility/services/utm.service";
import {MetaService} from "../../utility/services/meta.service";

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent implements OnInit{

  constructor(
    private demodeskService: DemodeskService,
    private analyticsService: AnalyticsService,
    private utmService: UtmService,
    private metaService: MetaService
  ) {}

  ngOnInit() {
    this.metaService.setThemeColor('#b3e0dc');
  }

  routeToDemoDesk(context: string) {
    this.demodeskService.navToDemodesk(context)
  }
  routeToTalentAgentOb(buttonId: string) {
    this.analyticsService.reportCheckoutTrackingEvent('InitiateCheckout', null, buttonId)
    window.open(environment.talentAgentFrontendUrl +  '/onboarding' + this.utmService.getUtmParamsAsString())
  }

}
