import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, UploadedFileData, TalentCoachRatingData, CoachingOfferData, TalentCoachData, ConsultantData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.TalentCoachController
 */

@Injectable()
@ResourceParams({})
export class TalentCoachResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/coach/assess',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _assessTalentCoach: IResourceMethodObservableStrict<TalentCoachRatingData, null, null, null> | undefined
    assessTalentCoach(requestBody: TalentCoachRatingData): Promise<null> {
        if (!this._assessTalentCoach) throw new Error("resource has not been properly initialized")
        return this._assessTalentCoach(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/talent/me/coaching/files/{fileId}/delete',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deleteCoachingFile: IResourceMethodObservableStrict<null, null, {fileId: number}, null> | undefined
    deleteCoachingFile(fileId: number): Promise<null> {
        if (!this._deleteCoachingFile) throw new Error("resource has not been properly initialized")
        return this._deleteCoachingFile(null, null, {fileId: fileId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/me/coaching/coachingOffers',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllCoachingOffersForTalent: IResourceMethodObservableStrict<null, null, null, CoachingOfferData[]> | undefined
    getAllCoachingOffersForTalent(): Promise<CoachingOfferData[]> {
        if (!this._getAllCoachingOffersForTalent) throw new Error("resource has not been properly initialized")
        return this._getAllCoachingOffersForTalent(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/me/coaching/files/coach',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCoachingFilesFromCoach: IResourceMethodObservableStrict<null, {coachingId: number}, null, UploadedFileData[]> | undefined
    getCoachingFilesFromCoach(requestParams: {coachingId: number}): Promise<UploadedFileData[]> {
        if (!this._getCoachingFilesFromCoach) throw new Error("resource has not been properly initialized")
        return this._getCoachingFilesFromCoach(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/me/coaching/coachingOffer',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getCoachingOfferByCoachingTypeId: IResourceMethodObservableStrict<null, {coachingTypeId: number}, null, CoachingOfferData> | undefined
    getCoachingOfferByCoachingTypeId(requestParams: {coachingTypeId: number}): Promise<CoachingOfferData> {
        if (!this._getCoachingOfferByCoachingTypeId) throw new Error("resource has not been properly initialized")
        return this._getCoachingOfferByCoachingTypeId(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/me/coaching/requestedAppointment',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getHasRequestedAppointmentForCurrentCoaching: IResourceMethodObservableStrict<null, null, null, JsonWrappedValue<boolean>> | undefined
    getHasRequestedAppointmentForCurrentCoaching(): Promise<boolean> {
        if (!this._getHasRequestedAppointmentForCurrentCoaching) throw new Error("resource has not been properly initialized")
        return this._getHasRequestedAppointmentForCurrentCoaching(null, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/me/coaching/files/me',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getOwnFilesForCoaching: IResourceMethodObservableStrict<null, {coachingId: number}, null, UploadedFileData[]> | undefined
    getOwnFilesForCoaching(requestParams: {coachingId: number}): Promise<UploadedFileData[]> {
        if (!this._getOwnFilesForCoaching) throw new Error("resource has not been properly initialized")
        return this._getOwnFilesForCoaching(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/me/coaching/files/{fileId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getStoredFileFromCoaching: IResourceMethodObservableStrict<null, null, {fileId: number}, Blob> | undefined
    getStoredFileFromCoaching(fileId: number): Promise<Blob> {
        if (!this._getStoredFileFromCoaching) throw new Error("resource has not been properly initialized")
        return this._getStoredFileFromCoaching(null, null, {fileId: fileId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/me/coach',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentCoach: IResourceMethodObservableStrict<null, null, null, TalentCoachData> | undefined
    getTalentCoach(): Promise<TalentCoachData> {
        if (!this._getTalentCoach) throw new Error("resource has not been properly initialized")
        return this._getTalentCoach(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/me/coach/profilePicture',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentCoachProfilePicture: IResourceMethodObservableStrict<null, null, null, Blob> | undefined
    getTalentCoachProfilePicture(): Promise<Blob> {
        if (!this._getTalentCoachProfilePicture) throw new Error("resource has not been properly initialized")
        return this._getTalentCoachProfilePicture(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/me/coach',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _requestTalentAgent: IResourceMethodObservableStrict<number[], null, null, ConsultantData> | undefined
    requestTalentAgent(requestBody: number[]): Promise<ConsultantData> {
        if (!this._requestTalentAgent) throw new Error("resource has not been properly initialized")
        return this._requestTalentAgent(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/me/coaching/unassign',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _unassignFromPresentCoaching: IResourceMethodObservableStrict<null, null, null, null> | undefined
    unassignFromPresentCoaching(): Promise<null> {
        if (!this._unassignFromPresentCoaching) throw new Error("resource has not been properly initialized")
        return this._unassignFromPresentCoaching(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/me/coaching/updateCoachingOffer',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateCoachingOfferForTalent: IResourceMethodObservableStrict<CoachingOfferData, null, null, CoachingOfferData> | undefined
    updateCoachingOfferForTalent(requestBody: CoachingOfferData): Promise<CoachingOfferData> {
        if (!this._updateCoachingOfferForTalent) throw new Error("resource has not been properly initialized")
        return this._updateCoachingOfferForTalent(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/talent/me/coaching/uploadFile',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _uploadFileForCoaching: IResourceMethodObservableStrict<File, {fileName: string}, null, Blob> | undefined
    uploadFileForCoaching(requestBody: File, requestParams: {fileName: string}): Promise<Blob> {
        if (!this._uploadFileForCoaching) throw new Error("resource has not been properly initialized")
        return this._uploadFileForCoaching(requestBody, requestParams, null).toPromise()
    }

}