export interface Checkpoint {
  boldText: string;
  description: string;
}

export class FrontendVideoData {
  slug: string;
  previewUrl: string;
  videoUrl: string;
  vimeo: string;
  topTitle: string;
  title: string;
  description: string;
  longDescription: string;
  duration: string;
  checkpoints: Checkpoint[];
  suggestedVideos: number[];

  constructor(
    slug: string,
    previewUrl: string,
    videoUrl: string,
    vimeo: string,
    topTitle: string,
    title: string,
    description: string,
    longDescription: string,
    duration: string,
    checkpoints: Checkpoint[],
    suggestedVideos: number[]
  ) {
    this.slug = slug;
    this.previewUrl = previewUrl;
    this.videoUrl = videoUrl;
    this.vimeo = vimeo;
    this.topTitle = topTitle;
    this.title = title;
    this.description = description;
    this.longDescription = longDescription;
    this.duration = duration;
    this.checkpoints = checkpoints;
    this.suggestedVideos = suggestedVideos;
  }
}





export const videoData = [

  // Was zählt wirklich bei der Arbeitgeberwahl?
  // 0
  new FrontendVideoData(
    'arbeitgeberwahl-faktoren',
    'assets/images/hr-preview/Arbeitgeberwahl.jpg',
    'assets/images/hr-preview/Arbeitgeberwahl.mp4',
    'https://player.vimeo.com/video/1050797354?h=8850d6ca56&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    'Die Gen Z auf Jobsuche',
    'Was zählt wirklich bei der Arbeitgeberwahl?',
    'In der heutigen Talentgewinnung ist es unerlässlich, die Recruitingstrategien an die Wünsche und ' +
      'Erwartungen junger Talente anzupassen. Im folgenden Video teilen die Interviewteilnehmer die entscheidenden ' +
      'Faktoren für die Wahl ihres ersten Arbeitgebers nach dem Studium.',

      `Hohes Einstiegsgehalt oder Flexibilität? Finde heraus, was wirklich zählt bei der Wahl des ersten
      Arbeitgebers nach dem Studium. In diesem Video tauchen wir tief in die Prioritäten und Erwartungen der Generation Z
      ein. Hier reagieren wir auf Interviews mit Talenten der Gen Z und setzen deren Erwartungen und Erfahrungen beim
      Berufseinstieg in den Vergleich mit den Ergebnissen unserer externen Umfrage
      <!-- <a href="/" class="description-link">Umfrage</a> -->
      unter Studierenden.<br>
      <br>
      Nach einem einführenden Blick auf das Thema Arbeitgeberwahl analysieren wir, wie junge Menschen ihren zukünftigen
      Arbeitgeber wahrnehmen und welche Faktoren für sie wirklich entscheidend sind. Neben Aspekten wie Work-Life-Balance,
      Weiterentwicklung und Unternehmenskultur zeigen die Interviews die realen Beweggründe und Bedenken junger Bewerber.
      Im Vergleich zur Umfrage<!-- <a href="/" class="description-link">Umfrage</a> --> wird deutlich, was Unternehmen heute bieten müssen, um bei der Generation Z auf dem Radar
      zu erscheinen. Befolge unsere drei Tipps aus dem Video und sichere dir die Spitzenkräfte von morgen.`,
    '14 Minuten',
    [
      {
        boldText: 'Gib deinem Unternehmen ein Gesicht:',
        description: 'Stärke deine Arbeitgebermarke durch authentische Einblicke in die Unternehmenskultur und ' +
          'überzeuge die Gen Z mit Transparenz und Authentizität.'
      },
      {
        boldText: 'Sorge für transparente und vollständige Stellenanzeige:',
        description: 'Kommuniziere klar deine Konditionen, Anforderungen und Benefits, so schaffst du einen ' +
          'vertrauenswürdigen Eindruck, um mehr Wunschbewerber anzuziehen.'
      },
      {
        boldText: 'Beachte die wichtigsten Faktoren der Arbeitgeberwahl:',
        description: 'Mit der richtigen Positionierung und Hervorhebung der Vorteile vom Unternehmen wirst du mit ' +
          'einem attraktiven Gesamtpaket die Top-Talente für dich gewinnen.'
      }
    ],
    [1 , 2, 3] // Verweist auf die Indizes anderer Videos in der Liste
),



  // Work-Life-Balance: Flexibilität statt 9-5?
  // 1

  new FrontendVideoData(
    'work-life-balance-durch-flexibilitaet',
    'assets/images/hr-preview/WLB.jpg',
    'assets/images/hr-preview/WLB.mp4',
    'https://player.vimeo.com/video/1050800555?h=f1688fed17&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    'Die Gen Z auf Jobsuche',
    'Work-Life-Balance: Flexibilität statt 9-5?',
    'Ein ausgewogenes Verhältnis zwischen Privatleben und Beruf ist für viele Talente entscheidend. ' +
      'Im nächsten Video berichten die Interviewteilnehmer von ihren Erwartungen und Ansichten zur Relevanz von ' +
      'Work-Life-Balance und Flexibilität bei der Wahl ihres Arbeitgebers.',

      `Was versteht die Generation Z unter Work-Life-Balance? Erfahre in unserem nächsten Video, wie die
      jungen Talente die Vereinbarkeit von Beruf und Privatleben bewerten. Wir klären auf, wie wichtig Flexibilität im
      Arbeitsalltag ist und welche Modelle für die Arbeitszeit und den Arbeitsort besonders attraktiv sind.<br>
      <br>
      Wir vergleichen auch hier die Erfahrungen und Ansichten unserer Interviewteilnehmer mit den Wünschen und Erwartungen
      der<!--<a href="/" class="description-link">online befragten Studierenden</a> --> online befragten Studierenden und geben dir wertvolle Tipps zur direkten Umsetzung. Wir enthüllen zudem, inwieweit
      die absolute Stundenanzahl auf Arbeit die Vereinbarkeit beeinflusst. Wir klären auf, wie viele Stunden die
      Nachwuchskräfte tatsächlich bereit sind zu arbeiten. Unser Vergleich zeigt unter anderem auch eindeutig, ob der
      Schlüssel für die Zufriedenheit der jungen Talente wirklich in den flexiblen Homeoffice-Möglichkeiten liegt. Setze
      unsere drei Empfehlungen aus dem Video um, gewinne und binde damit langfristig die Juniors an deinem Unternehmen.`,
    '13 Minuten',
    [
      {
        boldText: 'Entwickle und implementiere flexible Modelle für Arbeitszeit und -ort:',
        description: 'Vom Hybridmodell über Gleitzeit bis hin zu Workation – biete den Talenten die Freiheit, ' +
          'ihren Arbeitsalltag individuell zu gestalten.'
      },
      {
        boldText: 'Sammle zufriedene Mitarbeiterstimmen und nutze sie zielgerichtet:',
        description: 'Zeige nach außen, wie die Work-Life-Balance in deinem Unternehmen tatsächlich gelebt wird.'
      },
      {
        boldText: 'Zeige offen und transparent, welche Vorteile und Möglichkeiten du bietest:',
        description: 'Definiere klar und deutlich, was Flexibilität in deinem Unternehmen bedeutet, um Missverständnisse ' +
          'zu vermeiden und Vertrauen aufzubauen.'
      }
    ],
    [2, 3, 4]
),







  // Red und Green Flags bei Jobangeboten
  // 2

  new FrontendVideoData(
    'jobangebot-optimieren',
    'assets/images/hr-preview/Jobangebote.jpg',
    'assets/images/hr-preview/Jobangebote.mp4',
    'https://player.vimeo.com/video/1050802603?h=369f7b8aa5&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    'Die Gen Z auf Jobsuche',
    'Red und Green Flags bei Jobangeboten',
    'Welche Benefits überzeugen junge Talente wirklich und welche sollte man besser nicht als Vorteile ' +
      'verkaufen? In unserem nächsten Video erfährst du, welche echten Mehrwerte die Generation Z für ihren ersten ' +
      'Job nach dem Studium erwartet und welche No-Gos man als Recruiter vermeiden sollte.',

      `Die Stellenanzeige ist oft der erste Kontaktpunkt mit deinem Unternehmen – und du hast nur wenige
      Sekunden, um die Generation Z zu überzeugen. In unserem nächsten Video zeigen wir dir die größten No-Gos und besten
      Tipps, um ein unwiderstehliches Jobangebot zu gestalten. Erfahre aus den Interviews vor Ort und den Antworten
      der online befragten Studierenden, was junge Talente wirklich bewegt – und welche Faktoren sie dazu bringen,
      sich für oder gegen eine Bewerbung zu entscheiden.<br>
      <br>
      Erfahre, was junge Talente abschreckt – vom unscharf formulierten Stellenprofil bis hin zu den sogenannten
      „Standard-Benefits“, die eher als Selbstverständlichkeiten wahrgenommen werden. Unsere Interviewteilnehmer
      verraten auch, welche Highlights und Vorteile aus der Stellenanzeige herausgestochen sind und sie beim
      Berufseinstieg tatsächlich überzeugt haben. Der Vergleich mit unserer Online-Befragung informiert darüber,
      welche Benefits für die Generation Z wirklich relevant sind und welche eher ignoriert werden. Außerdem gehen wir
      auf häufige Fehler ein, die Unternehmen bei der Gestaltung von Stellenanzeigen machen und geben dir drei konkrete
      Empfehlungen, um dein Jobangebot zu optimieren und mehr Bewerbungen von der Gen Z zu erhalten.`,
    '15 Minuten',
    [
      {
        boldText: 'Verkaufe keine Selbstverständlichkeiten als Benefits:',
        description: 'Hebe dein Jobangebot mit echten Mehrwerten hervor. So fühlen sich die Talente besonders wertgeschätzt.'
      },
      {
        boldText: 'Biete sinnvolle Benefits:',
        description: 'Nicht nur ein wettbewerbsfähiges Gehalt bieten, sondern auch attraktive und passende ' +
          'Zusatzleistungen, die das Gesamtpaket abrunden.'
      },
      {
        boldText: 'Optimiere deine Stellenanzeige optisch:',
        description: 'Kommuniziere das Wichtigste auf einen Blick: Dein Angebot, deine Unternehmenskultur und die ' +
          'nächsten Schritte nach der Bewerbung.'
      }
    ],
    [3, 4, 0]
),













  // Woran scheitert es im Bewerbungsprozess?
  // 3

  new FrontendVideoData(
    'bewerbungsprozess-vereinfachen',
    'assets/images/hr-preview/Bewerbungsprozess.jpg',
    'assets/images/hr-preview/Bewerbungsprozess.mp4',
    'https://player.vimeo.com/video/1050803468?h=0644790a21&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    'Die Gen Z auf Jobsuche',
    'Woran scheitert es im Bewerbungsprozess?',
    'Welche Stolpersteine führen dazu, dass man die Top-Talente im Endspurt verliert? In diesem Video ' +
      'teilen die Interviewteilnehmer wertvolle Einblicke in ihre Erfahrungen beim Berufseinstieg. Sie verraten, was ' +
      'sie im Bewerbungsprozess positiv überzeugt und was eher abgeschreckt hat.',

      `Das Vorstellungsgespräch, die Bewerbungsunterlagen oder die Unklarheit darüber, was einen genau
      erwartet – welche dieser Herausforderungen bereiten den jungen Talenten die größten Sorgen auf dem Weg zum ersten
      Job nach dem Studium? In diesem Video teilen die Interviewteilnehmer ihre persönlichen Erfahrungen beim
      Berufseinstieg. Wir vergleichen diese dann mit den Ängsten und Unsicherheiten, die zukünftige Absolventen laut
      unserer Online-Umfrage am meisten beschäftigen.<br>
      <br>
      Die vor Ort befragten Talente der Gen Z geben spannende Einblicke in ihre positiven und negativen Erlebnisse,
      die ihnen im Bewerbungsprozess oder im Vorstellungsgespräch besonders im Gedächtnis geblieben sind. Sie verraten,
      was sie sich damals zur Unterstützung gewünscht hätten, insbesondere um den Ablauf zu vereinfachen. Erfahre aus
      erster Hand, welche Hürden vom Erstkontakt bis zur Anstellung die größten Herausforderungen darstellen. Am Ende
      des Videos geben wir dir drei wertvolle Tipps, wie du deine Prozesse optimieren und die Nachwuchskräfte von
      deinem Unternehmen überzeugen kannst.`,
    '16 Minuten',
    [
      {
        boldText: 'Kommuniziere alle Prozessdetails frühzeitig und halte die Bewerber regelmäßig informiert:',
        description: 'Biete den Bewerbern schon in der Stellenanzeige klare Informationen über den Ablauf, ' +
          'um Unsicherheiten zu verringern, Vertrauen aufzubauen und die Arbeitgebermarke zu stärken.'
      },
      {
        boldText: 'Vermeide unnötige Hürden im Bewerbungsprozess:',
        description: 'Vereinfache den Prozess, verzichte auf unnötige Stolpersteine wie z.B. das Anschreiben und ' +
          'konzentriere dich auf das Potenzial der Bewerber.'
      },
      {
        boldText: 'Fördere eine einladende Atmosphäre und führe ein Gespräch auf Augenhöhe:',
        description: 'Schaffe ein positives, wertschätzendes Bewerbererlebnis, gib realistische Einblicke und gewinne ' +
          'das Vertrauen der Talente. Denk daran, dass auch du dich bewirbst.'
      }
    ],
    [4, 0, 1]
),








  // Wie lassen sich Talente langfristig binden?
  // 4

  new FrontendVideoData(
    'talente-langfristig-binden',
    'assets/images/hr-preview/Mitarbeiterbindung.jpg',
    'assets/images/hr-preview/Mitarbeiterbindung.mp4',
    'https://player.vimeo.com/video/1050821860?h=24924202be&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    'Die Gen Z auf Jobsuche',
    'Wie lassen sich Talente langfristig binden?',
    'Die gezielte Bindung junger Talente erfordert ein tiefes Verständnis ihrer Erwartungen und ' +
      'persönlichen Bedürfnisse. In unserem nächsten Video verraten die befragten Talente, welche Faktoren sie ' +
      'tatsächlich dazu motivieren, langfristig im Unternehmen zu bleiben.',

    `Welche Faktoren machen wirklich den Unterschied, damit Mitarbeiter sich im Unternehmen wohlfühlen
      und bleiben? In unserem nächsten und letzten Video der Videoreihe erfährst du aus erster Hand, welche Erwartungen
      Absolventen an ihren ersten Arbeitgeber haben und wie die erste Phase im Job diese Erwartungen erfüllt –
      oder enttäuscht hat. Die befragten Talente berichten, ob ihr Einstieg ins Berufsleben reibungslos verlief und wo
      sie sich mehr Unterstützung von ihrem Arbeitgeber gewünscht hätten.<br><br>
      Wir gehen außerdem der Frage nach, welche Maßnahmen Unternehmen ergreifen könnten, um Kündigungen zu verhindern und
      junge Talente langfristig zu motivieren. Unsere Interviewteilnehmer verraten, was sie dazu gebracht hat, den ersten
      Job zu verlassen – und welche Veränderungen sie zum Bleiben bewegt hätten. Zusätzlich erhältst du konkrete Einblicke
      in die Wünsche der befragten Studierenden aus der Generation Z. Abschließend liefern wir dir drei wertvolle
      Empfehlungen, mit denen du deinen Arbeitsplatz gezielt attraktiver für die Spitzenkräfte von morgen gestalten kannst.`,
    '12 Minuten',
    [
      {
        boldText: 'Biete ein strukturiertes Onboarding und kontinuierliche Unterstützung: ',
        description: 'Die ersten Wochen im Vollzeitjob sind entscheidend für das Ankommen und die ' +
          'langfristige Bindung von Talenten.'
      },
      {
        boldText: 'Beachte die individuellen Bedürfnisse der Mitarbeiter: ',
        description: 'Frühzeitiges Erkennen und Aufgreifen von Warnsignalen oder Herausforderungen kann Frustration ' +
          'und Kündigungen vermeiden.'
      },
      {
        boldText: 'Achte auf langfristige Entwicklungsperspektiven: ',
        description: 'Fördere persönliche Karrierewege und implementiere eine offene, wertschätzende Feedbackkultur im Team, um die langfristige Zufriedenheit am Arbeitsplatz zu sichern.'
      }
    ],
    [0, 1, 2]
),

];
